import { BeerInfoPO } from './beerInfoPO';

export interface TapPO {
  tapId: number;
  beerName: string;
  beerTypeId?: number;
  daysOpened?: number;
  displayOrder?: number;
  kegAttachTimestamp?: any;
  kegDetachTimestamp?: any;
  kegDetached?: boolean;
  kegSize?: number;
  kegUsed?: number;
  logoKey?: string;
  maxOpenDuration?: number;
  online?: boolean;
  maintenance: boolean;
  lastPours?: Array<TapPourPO>;
  calibrationStatus?: CalibrationStatus;
  calibrationDocId?: string;
  beerInfo?: BeerInfoPO;
}

export interface TapPourPO {
  amount: number;
  timestamp: any;
}

export interface TapReportPO {
  tapId: number;
  beer: string;
}

export enum CalibrationStatus {
  NOT_CALIBRATED = 'NOT_CALIBRATED',
  WAITING = 'WAITING',
  NO_VALUE = 'NO_VALUE',
  CALIBRATED = 'CALIBRATED',
  RECALIBRATED = 'RECALIBRATED'
}
