import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import panzoom from 'panzoom';
import { EditTapModalComponent } from '../../../../modals/edit-tap-modal/edit-tap-modal.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersComponent implements AfterViewInit {
  @ViewChild('scene', {static: false}) scene: ElementRef;
  panZoomController;
  zoomLevels: number[];
  currentZoomLevel: number;
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  elTest = ELEMENT_DATA;
  displayedColumns: string[] = ['id', 'orderDate', 'orderStatus', 'deliveryDate'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private dialog: MatDialog,
              private cd: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.zoomLevels = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3];
    this.currentZoomLevel = this.zoomLevels[4];
    this.panZoomController = panzoom(this.scene.nativeElement, {
      zoomDoubleClickSpeed: 1,
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cd.detectChanges();
  }

  zoom() {
    const isSmooth = false;
    const scale = this.currentZoomLevel;

    if (scale) {
      const transform = this.panZoomController.getTransform();
      const deltaX = transform.x;
      const deltaY = transform.y;
      const offsetX = scale + deltaX;
      const offsetY = scale + deltaY;

      if (isSmooth) {
        this.panZoomController.smoothZoom(0, 0, scale);
      } else {
        this.panZoomController.zoomTo(offsetX, offsetY, scale);
      }
    }
  }

  zoomToggle(zoomIn: boolean) {
    const idx = this.zoomLevels.indexOf(this.currentZoomLevel);
    if (zoomIn) {
      if (typeof this.zoomLevels[idx + 1] !== 'undefined') {
        this.currentZoomLevel = this.zoomLevels[idx + 1];
      }
    } else {
      if (typeof this.zoomLevels[idx - 1] !== 'undefined') {
        this.currentZoomLevel = this.zoomLevels[idx - 1];
      }
    }
    if (this.currentZoomLevel === 1) {
      this.panZoomController.moveTo(0, 0);
      this.panZoomController.zoomAbs(0, 0, 1);
    } else {
      this.zoom();
    }
  }

  centerArea() {
    this.panZoomController.moveTo(0, 0);
    this.cd.detectChanges();
  }


  openEditTapModal(event: MouseEvent, id: number) {
    const dialogRef = this.dialog.open(EditTapModalComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'no-padding-dialog',
      data: {id}
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        console.log(data);
      }
    });
  }
}

export interface PeriodicElement {
  id: number;
  deliveryDate: string;
  orderStatus: string;
  orderDate: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {id: 1, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'delivered', orderDate: '11/12/12'},
  {id: 3, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'pending', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'delivered', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'pending', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'pending', orderDate: '11/12/12'},
  {id: 3, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'pending', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'delivered', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'pending', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'sent', orderDate: '11/12/12'},
  {id: 2, deliveryDate: '11/12/12', orderStatus: 'pending', orderDate: '11/12/12'}
];
