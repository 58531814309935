import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UsedBeerPO } from '../model/usedBeerPO';

@Injectable()
export class ReportRestService {

    protected basePath = environment.baseUrl;

    public defaultHeaders = new HttpHeaders();

    constructor(protected httpClient: HttpClient) {
    }

    public getAvailableBeerTypes(venue: string): Observable<UsedBeerPO[]> {
        const headers = this.defaultHeaders;

        return this.httpClient.get<any>(`${this.basePath}/report/used-beer?venue=${venue}`,
            {
                headers
            }
        );
    }
    public getMVPRepost(venue: string): Observable<any> {
        const headers = this.defaultHeaders;

        return this.httpClient.get<any>(`${this.basePath}/report/mvp?venue=${venue}`,
            {
                headers
            }
        );
    }

}
