import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ReportRestService } from '../../../../core/rest/api/reportRest.service';
import { UsedBeerPO } from '../../../../core/rest/model/usedBeerPO';
import { VenuePO } from '../../../../core/rest/model/venuePO';
import { VenueService } from '../../../../core/services/venue.service';

@Component({
  selector: 'app-beer-race',
  templateUrl: './beer-race.component.html',
  styleUrls: ['./beer-race.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeerRaceComponent implements OnInit, AfterViewInit {
  private selectedVenue: VenuePO;
  beers: UsedBeerPO[];
  private maxValue: number;
  private subscription: Subscription;
  showButton = false;
  counterValue = 10;
  private timeout: any;

  beerRaceIds = [
    'tab-value-1',
    'tab-value-2',
    'tab-value-3',
    'tab-value-4'
  ];

  constructor(private reportRestService: ReportRestService,
              private router: Router,
              private route: ActivatedRoute,
              private venueService: VenueService,
              private cd: ChangeDetectorRef) {

  }

  async ngOnInit() {
    this.route.queryParamMap.subscribe(async () => {
      this.selectedVenue = await this.venueService.getSelectedVenue();
      this.reportRestService.getAvailableBeerTypes(this.selectedVenue.id).subscribe((usedBeer: UsedBeerPO[]) => {
        this.beers = usedBeer.sort((a, b) => b.usedBeer - a.usedBeer).slice(0, 7).sort((a, b) => a.beerName.localeCompare(b.beerName));
        this.setMaxValue(usedBeer);
        this.cd.markForCheck();
      });
    });

  }

  ngAfterViewInit(): void {
    this.subscription = interval(60000)
      .pipe(
        flatMap(() => this.reportRestService.getAvailableBeerTypes(this.selectedVenue.id))
      ).subscribe((usedBeer: UsedBeerPO[]) => {
        this.beers = usedBeer.sort((a, b) => b.usedBeer - a.usedBeer).slice(0, 7).sort((a, b) => a.beerName.localeCompare(b.beerName));
        this.setMaxValue(usedBeer);
        this.cd.markForCheck();
      });
  }

  private setMaxValue(usedBeer: UsedBeerPO[]) {
    if (usedBeer.length > 0) {
      this.maxValue = Math.max.apply(Math, usedBeer.map(value => value.usedBeer));
      const maxValueInLiters = (this.maxValue / 1000);
      this.counterValue = Math.ceil(maxValueInLiters / 6) * 10;
      this.setTables();
      this.cd.markForCheck();
    }
  }

  private setTables() {
    if (this.counterValue >= 100) {
      this.setTablesByValue(10);
    } else {
      const lastTableValue = this.counterValue / 10 + 3;
      this.setTablesByValue(lastTableValue);
    }
  }

  private setTablesByValue(lastTableValue) {
    for (let i = 3; i >= 0; i--) {
      const element = document.getElementById(this.beerRaceIds[i]);
      if (element) {
        element.setAttribute('xlink:href', `../assets/beer-race/levels/${lastTableValue}.svg`);
        --lastTableValue;
      }
    }
  }

  getBeerPosition(beer: UsedBeerPO) {
    const counter = (60 / (this.counterValue * 1000));
    return beer.usedBeer * counter;
  }

  backToReport(param) {
    this.router.navigate(['/reports'], {queryParams: {venue: this.selectedVenue.id}});
  }

  get getDate() {
    return new Date();
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.showButton = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.showButton = false;
      this.cd.markForCheck();
    }, 2500);
  }

  fullScreen(): void {
    const elem = document.getElementById('beer-race-game') as any;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
}
