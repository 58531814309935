import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import BigNumber from 'bignumber.js';
import { Chart } from 'chart.js';
import * as crypto from 'crypto-js';
import * as Rainbow from 'rainbowvis.js';
import * as R from 'ramda';
import { environment } from '../../../environments/environment';
import { TapRestService } from '../../core/rest';
import { ReportRestService } from '../../core/rest/api/reportRest.service';
import { TapReportPO } from '../../core/rest/model/tapPO';
import { VenuePO } from '../../core/rest/model/venuePO';
import { VenueService } from '../../core/services/venue.service';
import { PreloaderService } from '../../shared/services/preloader.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  selectedVenue: VenuePO;
  modeUrl;
  availableTaps: TapReportPO[];
  selectedTap: TapReportPO;
  showReports = false;
  colorsToGradient = ['#70520F', '#DEA521', '#EFD9B0', '#9B9B9B'];
  @ViewChild('daily-beer', {static: false}) dailyBeer: ElementRef;
  @ViewChild('average-pour-volume', {static: false}) averagePourVolume: ElementRef;
  @ViewChild('beer-volume-share', {static: false}) beerVolumeShare: ElementRef;
  beerVolumeShareChart = {
    container: [],
    data: {
      datasets: [{
        data: [],
        backgroundColor: []
      }],
      labels: []
    },
    options: {
      legend: {
        labels: {
          fontColor: '#9B9B9B'
        }
      }
    }
  };
  avaragePourChart = {
    container: [],
    data: {
      datasets: [],
      labels: []
    },
    options: {
      legend: {
        labels: {
          fontColor: '#9B9B9B'
        }
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#9B9B9B'
          }
        }],
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'volume (in liters)',
            fontColor: '#9B9B9B'
          },
          ticks: {
            fontColor: '#9B9B9B'
          }
        }]
      }
    }
  };
  dailyBeerChart = {
    container: [],
    data: {
      datasets: [],
      labels: []
    },
    options: {
      legend: {
        labels: {
          fontColor: '#9B9B9B'
        }
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#9B9B9B'
          }
        }],
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'volume (in liters)',
            fontColor: '#9B9B9B'
          },
          ticks: {
            fontColor: '#9B9B9B'
          }
        }]
      }
    }
  };
  headline: any;
  daysOfTheWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  private colors: string[];

  constructor(private venueService: VenueService,
              private tapRestService: TapRestService,
              private route: ActivatedRoute,
              private preloader: PreloaderService,
              private translate: TranslateService,
              private reportService: ReportRestService,
              private sanitizer: DomSanitizer) {
  }

  async ngOnInit() {
    this.route.queryParamMap.subscribe(async () => {
      this.preloader.show();
      this.selectedVenue = await this.venueService.getSelectedVenue();
      if (this.selectedVenue) {
        this.tapRestService.getReportTapList((this.selectedVenue.id)).subscribe(res => {
          this.availableTaps = res;
          this.changeTap(this.availableTaps[0]);
        });
        this.reportService.getMVPRepost(this.selectedVenue.id).subscribe((reportObject: any) => {
          this.headline = reportObject.headline;
          this.prepareBeerVolumeShareReport(reportObject);
          this.prepareBeerVolumePerDayReport(reportObject);
          this.prepareBeerVolumeDailyReport(reportObject);
          this.preloader.hide();
        });
      } else {
        this.preloader.hide();
      }
    });
  }


  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  changeTap(selectedElement: TapReportPO): void {

    if (selectedElement && selectedElement.tapId) {
      this.selectedTap = selectedElement;
      const timestamp = Math.floor(Date.now() / 1000);
      const decimalVenue = new BigNumber(this.selectedVenue.id, 16).toString(10);
      // tslint:disable-next-line:max-line-length
      const url = `https://modeanalytics.com/tapit_solutions/reports/2d78b9620998/embed?access_key=7032afb5e01a3c0b07455e90&max_age=&param_tap_id=${selectedElement.tapId}&param_venue_hash=${decimalVenue}&run=now&timestamp=${timestamp}`;
      const signature = crypto.HmacSHA256(`GET,,1B2M2Y8AsgTpgAmY7PhCfg==,${url},${timestamp}`, environment.modeAnalyticsSecretKey);
      this.modeUrl = this.getSafeUrl(`${url}&signature=${crypto.enc.Hex.stringify(signature)}`);
      // tslint:disable-next-line:max-line-length
      this.showReports = true;
    }
  }

  private prepareBeerVolumePerDayReport(reportObject: any) {

    const canvas = (document.getElementById('average-pour-volume') as any);
    // @ts-ignore
    if (this.avaragePourChart && this.avaragePourChart.destroy) {
      this.avaragePourChart.data = {
        datasets: [],
        labels: []
      };
      // @ts-ignore
      this.avaragePourChart.destroy();
    }
    const beerUniqueMap = new Map();

    const beersSortedByDays = reportObject.dayOfWeekBeerVolumePerBrand.sort((a, b) => {
      return this.daysOfTheWeek.indexOf(a.dayOfWeek) - this.daysOfTheWeek.indexOf(b.dayOfWeek);
    });

    beersSortedByDays.forEach(item => {
      item.beerVolumePerDay.forEach(beer => {
        const data = [0, 0, 0, 0, 0, 0, 0];
        beerUniqueMap.set(beer.beerName, data);
      });
    });

    reportObject.dayOfWeekBeerVolumePerBrand.forEach(item => {
      const dayOfTheWeekIndex = this.daysOfTheWeek.findIndex(day => day === item.dayOfWeek);
      item.beerVolumePerDay.forEach(beer => {
        const currentValue = beerUniqueMap.get(beer.beerName)[dayOfTheWeekIndex];
        beerUniqueMap.get(beer.beerName)[dayOfTheWeekIndex] = (currentValue + beer.volume) / 1000;
      });
    });

    [...beerUniqueMap].forEach((beer, index) => {
      if (beer[0] === '--others--') {
        beer[0] = this.translate.instant('others');
      }
      this.avaragePourChart.data.datasets.push({
        label: beer[0],
        backgroundColor: this.colors[index],
        data: beer[1]
      });
    });

    this.avaragePourChart.data.datasets.sort((a, b) => a.label.localeCompare(b.label));
    this.avaragePourChart.data.labels = this.daysOfTheWeek;
    this.avaragePourChart = new Chart(canvas.getContext('2d'), {
      type: 'bar',
      data: this.avaragePourChart.data,
      options: this.avaragePourChart.options
    });
  }

  private prepareBeerVolumeShareReport(reportObject: any) {
    const canvas = (document.getElementById('beer-volume-share') as any);

    // @ts-ignore
    if (this.beerVolumeShareChart && this.beerVolumeShareChart.destroy) {
      // @ts-ignore
      this.beerVolumeShareChart.destroy();
    }
    const groupedBeer = this.prepareDataToDisplayVolumeShareReport(reportObject, 10);
    this.colors = this.getGradientColors(groupedBeer.length, this.colorsToGradient);
    this.beerVolumeShareChart.data.datasets[0].data = groupedBeer.map(value => value.volume / 1000);
    setTimeout(() => {
      this.beerVolumeShareChart.data.datasets[0].backgroundColor = this.dailyBeerChart.data.datasets.map(value => value.backgroundColor);
      this.beerVolumeShareChart.data.labels = groupedBeer.map(value => value.beerName);

      this.beerVolumeShareChart = new Chart(canvas.getContext('2d'), {
        type: 'doughnut',
        data: this.beerVolumeShareChart.data,
        options: this.beerVolumeShareChart.options
      });
    }, 0);

  }

  private prepareDataToDisplayVolumeShareReport(reportObject: any, maxCountItem: number) {
    let groupedBeer = R.clone(reportObject.beerVolumeShare);
    groupedBeer.sort((a, b) => a.beerName.localeCompare(b.beerName));

    if (groupedBeer.length > maxCountItem) {
      const otherItem = {beerName: this.translate.instant('others'), volume: 0};
      for (let i = maxCountItem; i < groupedBeer.length; i++) {
        otherItem.volume += groupedBeer[i].volume;
      }

      groupedBeer = groupedBeer.splice(0, maxCountItem);
      groupedBeer.push(otherItem);
    }
    return groupedBeer;
  }

  private prepareBeerVolumeDailyReport(reportObject: any) {
    const canvas = (document.getElementById('daily-beer') as any);
    // @ts-ignore
    if (this.dailyBeerChart && this.dailyBeerChart.destroy) {
      this.dailyBeerChart.data = {
        datasets: [],
        labels: []
      };
      // @ts-ignore
      this.dailyBeerChart.destroy();
    }

    reportObject.dailyBeerVolumePerBrand.sort((x, y) => {
      return +new Date(x.date) - +new Date(y.date);
    });

    this.dailyBeerChart.data.labels = reportObject.dailyBeerVolumePerBrand.map(value => {
      return value.date.slice(0, 10);
    });

    const beerUniqueMap = new Map();

    reportObject.dailyBeerVolumePerBrand.forEach(value => {
      value.beerVolumePerDay.forEach(value2 => {
        const mapEl = beerUniqueMap.get(value2.beerName);
        if (mapEl) {
          beerUniqueMap.set(value2.beerName, []);
        } else {
          beerUniqueMap.set(value2.beerName, []);
        }
      });
    });

    const beerListNames = [...beerUniqueMap].map(value => value[0]);
    beerUniqueMap.clear();
    const resultArray = [];
    reportObject.dailyBeerVolumePerBrand.forEach((value, index) => {
      const tempArray = Array(beerListNames.length).fill(0);

      value.beerVolumePerDay.forEach(value2 => {
        const beerIndex = beerListNames.findIndex(beer => beer === value2.beerName);
        tempArray[beerIndex] = value2.volume / 1000;
        beerUniqueMap.set(value2.beerName, []);

      });
      resultArray.push(tempArray);
    });

    const transposedMatrix = this.transposeMatrix(resultArray);
    [...beerUniqueMap].forEach((beer, index) => {
      if (beer[0] === '--others--') {
        beer[0] = this.translate.instant('others');
      }
      this.dailyBeerChart.data.datasets.push({
        label: beer[0],
        backgroundColor: this.colors[index],
        data: transposedMatrix[index]
      });
    });

    this.dailyBeerChart.data.datasets.sort((a, b) => a.label.localeCompare(b.label));
    this.dailyBeerChart = new Chart(canvas.getContext('2d'), {
      type: 'bar',
      data: this.dailyBeerChart.data,
      options: this.dailyBeerChart.options
    });

  }

  transposeMatrix(array) {
    if (array.length !== 0) {
      return Object.keys(array[0]).map((c) => {
        return array.map((r) => {
          return r[c];
        });
      });
    } else {
      return [];
    }
  }

  getGradientColors(numberOfItems: number, spectrumColors: string[]) {
    if (numberOfItems > 1) {
      const rainbow = new Rainbow();
      rainbow.setNumberRange(0, numberOfItems);
      rainbow.setSpectrumByArray(spectrumColors);
      const s = [];
      for (let i = 1; i <= numberOfItems; i++) {
        const hexColour = rainbow.colourAt(i);
        s.push(`#${hexColour}`);
      }
      return s;
    } else {
      return ['#dea521'];
    }
  }
}
