import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TapRestService } from '../../../../core/rest';
import { BeerPO } from '../../../../core/rest/model/beerPO';
import { ManagementTapPO } from '../../../../core/rest/model/managementTapPO';
import { TapPO } from '../../../../core/rest/model/tapPO';
import { VenuePO } from '../../../../core/rest/model/venuePO';
import { VenueService } from '../../../../core/services/venue.service';
import { BeerListModalComponent } from '../../../../modals/beer-list-modal/beer-list-modal.component';
import { CalibrationModalComponent } from '../../../../modals/calibration-modal/calibration-modal.component';
import { KegSizeModalComponent } from '../../../../modals/keg-size-modal/keg-size-modal.component';
import { PreloaderService } from '../../../../shared/services/preloader.service';


@Component({
  selector: 'app-taps-management',
  templateUrl: './taps-management.component.html',
  styleUrls: ['./taps-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TapsManagementComponent implements OnInit {
  private selectedVenue: VenuePO;
  managementTaps: ManagementTapPO[];
  previousTap: TapPO;
  selectedBeer: BeerPO;
  kegSize: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private venueService: VenueService,
              private preloader: PreloaderService,
              private tapRestService: TapRestService,
              private cd: ChangeDetectorRef) {
  }

  async ngOnInit() {
    this.route.queryParamMap.subscribe(async () => {
      this.preloader.show();
      this.selectedVenue = await this.venueService.getSelectedVenue();
      this.fetchManagementTaps();
      this.cd.markForCheck();
    });
  }

  private fetchManagementTaps() {
    this.tapRestService.getTapsManagementList(this.selectedVenue.id).subscribe(managementTaps => {
      this.managementTaps = this.sortTapsByDisplayOrder(managementTaps);
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }

  openBeerTypeModal(editedTap: ManagementTapPO): void {
    const dialogRef = this.dialog.open(BeerListModalComponent, {
      width: '600px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.back) {
        this.fetchManagementTaps();
      } else if (result) {
        this.selectedBeer = result;
        this.chooseKegSize(editedTap);
        this.cd.markForCheck();
      }
    });
  }

  chooseKegSize(editedTap: ManagementTapPO): void {
    const dialogRef = this.dialog.open(KegSizeModalComponent, {
      width: '600px',
      disableClose: true,
      data: {
        beerName: this.selectedBeer.name
      }
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data && data.back === true) {
        this.openBeerTypeModal(editedTap);
        this.cd.markForCheck();
      } else if (data) {
        this.kegSize = data;
        this.attachNewKeg(editedTap);
        this.cd.markForCheck();
      }
    });
  }

  private attachNewKeg(editedTap: ManagementTapPO) {
    this.tapRestService.attachKeg(editedTap.tapId, {
      beerTypeId: this.selectedBeer.id,
      kegSize: this.kegSize
    }).subscribe((tap: TapPO) => {
      this.openCalibrationModal(tap);
      this.cd.markForCheck();
    }, (error) => {
    });
  }

  openCalibrationModal(tapPO: TapPO): void {
    const dialogRef = this.dialog.open(CalibrationModalComponent, {
      width: '600px',
      disableClose: true,
      data: {
        selectedBeer: this.selectedBeer,
        tapId: tapPO.tapId,
        kegSize: this.kegSize,
        calibrationDocId: tapPO.calibrationDocId
      }
    });

    dialogRef.afterClosed().pipe(
      switchMap(() => this.tapRestService.getTapsManagementList(this.selectedVenue.id))
    ).subscribe(managementTaps => {
      this.managementTaps = this.sortTapsByDisplayOrder(managementTaps);
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }

  configureLine(editedTap: ManagementTapPO) {
    if (editedTap.beerAttached === null) {
      this.openBeerTypeModal(editedTap);
    } else {
      this.tapRestService.detachKeg(editedTap.tapId).subscribe((tap: TapPO) => {
        this.openBeerTypeModal(editedTap);
        this.cd.markForCheck();
      });
    }

  }

  renewKeg(tapId: number) {
    this.runHttpAndFetchManagementList(this.tapRestService.renewKeg(tapId));
  }

  deactivateMaintenance(tapId: number) {
    this.runHttpAndFetchManagementList(this.tapRestService.deactivateMaintenance(tapId));

  }

  activateMaintenance(tapId: number) {
    this.runHttpAndFetchManagementList(this.tapRestService.activateMaintenance(tapId));
  }

  sortTapsByDisplayOrder(managementTaps: ManagementTapPO[]): ManagementTapPO[] {
    return [...managementTaps].sort((a, b) => {
      return (a.displayOrder != null ? a.displayOrder : Infinity) - (b.displayOrder != null ? b.displayOrder : Infinity);
    });
  }

  runHttpAndFetchManagementList(httpObservable: Observable<any>) {
    httpObservable.pipe(
      tap(() => this.preloader.show()),
      switchMap(() => this.tapRestService.getTapsManagementList(this.selectedVenue.id))
    ).subscribe(managementTaps => {
      this.managementTaps = this.sortTapsByDisplayOrder(managementTaps);
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }
}
