export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyACbmm5s8LpRp9GtaNjKCJsMnSzGecXMsM',
    authDomain: 'tapit-prod.firebaseapp.com',
    databaseURL: 'https://tapit-prod.firebaseio.com',
    projectId: 'tapit-prod',
    storageBucket: 'tapit-prod.appspot.com',
    messagingSenderId: ''
  },
  auth0_domain: 'tapitsolutions-prod.eu.auth0.com',
  auth0_client_id: 'ZndemsSPAx5D4CW1KxAtKj3th927LDAa',
  auth0_audience: 'https://tapitsolutions-prod.com',
  baseUrl: 'https://service.tapitsolutions.com:443/api',
  modeAnalyticsSecretKey: '5170f765aee4814e7ad4a226'
};
