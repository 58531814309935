import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PreloaderService } from '../../../shared/services/preloader.service';

@Injectable()
export class AuthService {

  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth0_domain,
      client_id: environment.auth0_client_id,
      redirect_uri: `${window.location.origin}/callback`,
      audience: environment.auth0_audience

    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1),
    catchError(err => throwError(err))
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated()))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  private accessTokenSubject$ = new BehaviorSubject<string>(null);
  accessToken$ = this.accessTokenSubject$.asObservable();

  loggedIn: boolean = null;

  getTokenSilently$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getTokenSilently()))
  );
  userRoles = new BehaviorSubject(null);

  constructor(private router: Router,
              private preloader: PreloaderService,
              private logger: NGXLogger) {
    this.logger.trace('AuthService running');
  }

  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options)))
    );
  }

  localAuthSetup() {
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          return combineLatest(
            this.getUser$(),
            this.getTokenSilently$
          );
        }
        return of(loggedIn);
      })
    );
    const checkAuthSub = checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {

      if (response) {
        const user = response[0];
        const token = response[1];

        this.setRoles(user);
        this.userProfileSubject$.next(user);
        this.accessTokenSubject$.next(token);
      }
      this.loggedIn = !!response;
      checkAuthSub.unsubscribe();
    });
  }

  login(redirectPath: string = '/') {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: {target: redirectPath}
      });
    });
  }

  handleAuthCallback() {
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.auth0Client$.pipe(
      // Have client, now call method to handle auth callback redirect
      concatMap(() => this.handleRedirectCallback$),
      tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; create stream returning
        // user data, token, and authentication status
        return combineLatest(
          this.getUser$(),
          this.getTokenSilently$,
          this.isAuthenticated$
        );
      })
    );
    authComplete$.subscribe(([user, token, loggedIn]) => {
      this.userProfileSubject$.next(user);
      this.accessTokenSubject$.next(token);
      this.loggedIn = loggedIn;
      this.setRoles(user);
      this.router.navigateByUrl(targetRoute);
    });
  }


  private setRoles(user) {
    if (user) {
      this.userRoles.next(user['https://tapitsolutions.com/roles']);
      if (this.userRoles.value.length === 0) {
        this.router.navigate(['error']);
      }
    }
  }

  getRoles(): string | string[] {
    if (this.userRoles) {
      return this.userRoles.value;
    }
  }

  hasPermission(pageRoles: string[], userRoles?: string[]) {
    if (pageRoles && userRoles) {
      const roleIntersection = pageRoles.filter(value => userRoles.includes(value));
      return roleIntersection.length > 0;
    } else if (pageRoles && this.getRoles()) {
      const roleIntersection = pageRoles.filter(value => this.getRoles().includes(value));
      return roleIntersection.length > 0;
    }
  }

  logout() {
    this.preloader.show(true);
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: environment.auth0_client_id,
        returnTo: `${window.location.origin}`
      });
    });
    localStorage.removeItem('venue');
  }
}
