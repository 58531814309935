import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmation-information-modal',
  templateUrl: 'confirmation-process-modal.html',
  styleUrls: ['./confirmation-process-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationProcessModalComponent implements OnInit {
  information: string;
  user: string;

  constructor(private dialogRef: MatDialogRef<ConfirmationProcessModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.information = this.data.information;
      this.user = this.data.user;
      this.cd.markForCheck();
    }
  }

  closeModal(result: boolean) {
    this.dialogRef.close(result);
  }

}
