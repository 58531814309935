import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { EditUserModalComponent } from '../../../../modals/edit-user-modal/edit-user-modal.component';
import { InviteUserModalComponent } from '../../../../modals/invite-user-modal/invite-user-modal.component';
import { RemoveUserModalComponent } from '../../../../modals/remove-user-modal/remove-user-modal.component';

@Component({
  selector: 'app-user-mananagement',
  templateUrl: './user-mananagement.component.html',
  styleUrls: ['./user-mananagement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMananagementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  displayedColumns: string[] = ['status', 'role', 'name', 'email', 'lastLogin', 'action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private cd: ChangeDetectorRef) {
    this.cd.markForCheck();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cd.markForCheck();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  openSnackBar(message: string, styleClass?: string) {
    this.snackBar.open(message, null, {
      duration: 2500,
      panelClass: styleClass ? styleClass : 'primary-snackbar'
    });
  }

  openInviteUserModal(): void {
    const dialogRef = this.dialog.open(InviteUserModalComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'no-padding-dialog'
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        // todo: Add user endpoint
        this.openSnackBar('User invitation sended');
        console.log(data);
      }
    });
  }

  openRemoveUserModal(user: any) {
    const dialogRef = this.dialog.open(RemoveUserModalComponent, {
      width: '600px',
      disableClose: true,
      panelClass: 'no-padding-dialog',
      data: {
        user
      }
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        // todo: Remove user endpoint
        this.openSnackBar('User remove endpoint');
        console.log(data);
      } else {
        this.openEditUserModal(user);
      }
    });
  }

  openEditUserModal(element: any) {
    const dialogRef = this.dialog.open(EditUserModalComponent, {
      width: '600px',
      disableClose: true,
      data: {
        user: element
      },
      panelClass: 'no-padding-dialog'
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data && data.delete === true) {
        this.openRemoveUserModal(data.user);
      } else if (data) {
        // todo: Update user endpoint
        this.openSnackBar('User updated');
        console.log(data);
      }
    });
  }
}

export interface PeriodicElement {
  id: number;
  status: string;
  role: string;
  name: string;
  email: string;
  lastLogin: string;
  action?: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {id: 1, name: 'Jan', role: 'badmin', email: 'test@test.pl', status: 'warning', lastLogin: '11/12/12'},
  {id: 2, name: 'Adam', role: 'admin', email: 'test@test.pl', status: 'error', lastLogin: '11/12/12'},
  {id: 3, name: 'Teodor', role: 'cadmin', email: 'test@test.pl', status: 'warning', lastLogin: '11/12/12'},
  {id: 2, name: 'Igor', role: 'admin', email: 'test@test.pl', status: 'warning', lastLogin: '11/12/12'},
  {id: 2, name: 'Ewa', role: 'admin', email: 'test@test.pl', status: 'ok', lastLogin: '11/12/12'},
  {id: 2, name: 'Emilka', role: 'admin', email: 'test@test.pl', status: 'warning', lastLogin: '11/12/12'},
  {id: 2, name: 'Krzysiu', role: 'admin', email: 'test@test.pl', status: 'error', lastLogin: '11/12/12'},
  {id: 2, name: 'Jarek', role: 'admin', email: 'test@test.pl', status: 'warning', lastLogin: '11/12/12'},
  {id: 2, name: 'Milena', role: 'admin', email: 'test@test.pl', status: 'warning', lastLogin: '11/12/12'},
  {id: 2, name: 'Gosia', role: 'admin', email: 'test@test.pl', status: 'ok', lastLogin: '11/12/12'}
];
