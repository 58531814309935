import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-remove-user-modal',
  templateUrl: 'remove-user-modal.html',
  styleUrls: ['./remove-user-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoveUserModalComponent implements OnInit {
  user: any;

  private formGroup: FormGroup;

  constructor(private dialogRef: MatDialogRef<RemoveUserModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {
    this.prepareFormGroup();
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    if (this.data) {
      console.log(this.data);
      this.user = this.data.user;
      this.formGroup.patchValue(this.user);
    }
  }

  dismiss(data: boolean) {
    if (data) {
      this.dialogRef.close(this.formGroup.getRawValue());
    } else {
      this.dialogRef.close(false);
    }
  }

  prepareFormGroup(): void {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern(Utils.emailValidator)]),
      role: new FormControl('', Validators.required)
    });
  }

}
