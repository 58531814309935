import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef } from '@angular/material';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-new-customer-modal',
  templateUrl: 'new-customer-modal.html',
  styleUrls: ['./new-customer-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewCustomerModalComponent implements OnInit {
  formGroup: FormGroup;


  constructor(private dialogRef: MatDialogRef<NewCustomerModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {
    this.prepareFormGroup();
    this.cd.markForCheck();
  }

  ngOnInit(): void {

  }

  closeModal(result: boolean) {
    if (result) {
      this.dialogRef.close(this.formGroup.getRawValue());
    } else {
      this.dialogRef.close(result);
    }
  }

  prepareFormGroup(): void {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern(Utils.emailValidator)]),
      venueName: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      isOtherAddress: new FormControl(),
      otherAddress: new FormControl(''),
      tapsNumber: new FormControl(null, [Validators.required, Validators.min(1)]),
      invoiceDetails: new FormControl('', Validators.required)
    });
  }

  changeAddressValidator(checkbox: MatCheckboxChange) {
    const otherAddress = this.formGroup.get('otherAddress');
    if (checkbox.checked) {
      otherAddress.setValidators(Validators.required);
    } else {
      otherAddress.clearValidators();
      otherAddress.reset('');
    }
  }

  get getIsOtherAddressValue() {
    return this.formGroup.get('isOtherAddress').value;
  }
}
