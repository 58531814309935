import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerManagementComponent } from './components/customer-management/customer-management.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataDrawingComponent } from './components/data-drawing/data-drawing.component';
import { ErrorComponent } from './components/error/error.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BeerRaceComponent } from './components/report/components/beer-race/beer-race.component';
import { ReportComponent } from './components/report/report.component';
import { TapDetailComponent } from './components/tap-detail/tap-detail.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { TapsManagementComponent } from './components/venue-management/components/taps-management/taps-management.component';
import { VenueManagementComponent } from './components/venue-management/venue-management.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CallbackPageComponent } from './core/security/component/callback-page.component';
import { Roles } from './core/security/model/roles';
import { SettingsComponent } from './settings/settings.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {roles: [Roles.bronze, Roles.bartender, Roles.owner, Roles.admin]}
  },
  {
    path: 'tap/:tapId',
    component: TapDetailComponent,
    canActivate: [AuthGuard],
    data: {roles: [Roles.bronze, Roles.bartender, Roles.owner, Roles.admin]}
  },
  {
    path: 'reports',
    component: ReportComponent,
    canActivate: [AuthGuard],
    data: {roles: [Roles.bartender, Roles.owner, Roles.admin]}
  },
  {
    path: 'venue-management',
    component: VenueManagementComponent,
    canActivate: [AuthGuard],
    data: {roles: [Roles.owner, Roles.admin]},
    children: [
      {
        path: 'taps', component: TapsManagementComponent,
        canActivate: [AuthGuard],
        data: {roles: [Roles.owner, Roles.admin]}
      },
      // {path: 'beers', component: BeerMananagementComponent, canDeactivate: [PendingChangesGuard]},
      // {path: 'users', component: UserMananagementComponent},
      {path: '', redirectTo: 'taps', pathMatch: 'full'},
      {path: '**', component: PageNotFoundComponent}
    ]
  },
  {
    path: 'customer-management',
    component: CustomerManagementComponent,
    canActivate: [AuthGuard],
    data: {roles: [Roles.admin]}
  },
  {
    path: 'callback',
    component: CallbackPageComponent
  },
  {
    path: 'drawing',
    component: DataDrawingComponent
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports/beer-race',
    component: BeerRaceComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
