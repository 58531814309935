import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PreloaderService {
  private loaderSubject = new BehaviorSubject<LoaderState>({show: true});
  loaderState = this.loaderSubject.asObservable();

  constructor() {
  }

  show(initApp?: boolean) {
    this.loaderSubject.next({show: true, initApp} as LoaderState);
  }

  hide() {
    this.loaderSubject.next({show: false} as LoaderState);
  }
}

export interface LoaderState {
  show: boolean;
  initApp?: boolean;
}
