import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { OrderPO } from '../model/orderPO';
import { OrderStatusPO } from '../model/orderStatusPO';
import { OrderUserPO } from '../model/orderUserPO';

@Injectable()
export class OrderRestService {
  protected basePath = environment.baseUrl;

  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {
  }

  public startFullOrderProcess(orderInfo: OrderPO): Observable<any> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/order`,
      orderInfo,
      {
        headers
      }
    );
  }

  public retrieveOrders(): Observable<OrderStatusPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/order`,
      {
        headers
      }
    );
  }

  public startOrderProcessForVenue(orderInfo: OrderPO): Observable<any> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/order/venue`,
      orderInfo,
      {
        headers
      }
    );
  }

  public startOrderProcessForTaps(orderInfo: OrderPO): Observable<any> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/order/taps`,
      orderInfo,
      {
        headers
      }
    );
  }

  public retrieveUsers(): Observable<OrderUserPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/form/user`,
      {
        headers
      }
    );
  }
  public retrieveUserVenues(email: string): Observable<string[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/form/venue?email=${email}`,
      {
        headers
      }
    );
  }
  public completeOrderTask(processId: string, currentTaskName: string): Observable<string[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/order/task?processId=${processId}&currentTaskName=${currentTaskName}`,
      {
        headers
      }
    );
  }

}
