import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BeerPO, BeerTransferObject } from '../model/beerPO';

@Injectable()
export class BeerRestService {

  protected basePath = environment.baseUrl;

  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {
  }

  public getAllBeerTypes(): Observable<BeerPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/beer_type/all`,
      {
        headers
      }
    );
  }

  public getAvailableBeerTypes(venue: string): Observable<BeerPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/beer_type/available?venue=${venue}`,
      {
        headers
      }
    );
  }

  public searchBeersByQuery(query: string, pageNumber: number = 0): Observable<BeerPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/beer?q=${query}&page=${pageNumber}`,
      {
        headers
      }
    );
  }

  public updateAvailableBeerTypes(venue: string, beerList: BeerTransferObject[]): Observable<BeerPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.put<any>(`${this.basePath}/beer_type/available?venue=${venue}`,
      beerList,
      {
        headers
      }
    );
  }
}
