import { BeerPO } from '../core/rest/model/beerPO';

export class Utils {
    // tslint:disable-next-line:max-line-length
    static emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    static removeItemFromArray(collection: BeerPO[], item: BeerPO): void {
        const index = collection.indexOf(item);
        if (index > -1) {
            collection.splice(index, 1);
        }
    }
}
