import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { InlineSVGModule } from 'ng-inline-svg';
import { FlipModule } from 'ngx-flip';
import { BarrelLevelComponent } from './components/barrel-level/barrel-level.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from './material.module';
import { TruncatePipe } from './pipes/truncate.pipe';
import { MenuService } from './services/menu.service';

@NgModule({
  declarations: [
    BarrelLevelComponent,
    HeaderComponent,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    FlipModule,
    MaterialModule,
    InlineSVGModule,
    TranslateModule,
    NgxGraphModule
  ],
  exports: [
    FlipModule,
    BarrelLevelComponent,
    HeaderComponent,
    MaterialModule,
    InlineSVGModule,
    NgxGraphModule,
    TruncatePipe
  ],
  providers: [
    MenuService
  ]
})
export class SharedModule {
}
