import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as SvgPanZoom from 'svg-pan-zoom';

@Component({
  selector: 'app-data-drawing',
  templateUrl: './data-drawing.component.html',
  styleUrls: [
    './data-drawing.component.scss',
    './beer-map-colors.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataDrawingComponent implements OnInit, AfterViewInit {

  @ViewChild('svgRoot', {static: false})
  svgRoot: ElementRef<HTMLElement>;
  currentOpenBar = 'color-bar';

  constructor(private cd: ChangeDetectorRef) {
  }

  map = {};

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.svgRoot.nativeElement.removeAttribute('class');
    this.openBar(this.currentOpenBar);
    setTimeout(() => {
      this.svgRoot.nativeElement.classList.add('beer-map', 'active', 'belgian-witbier', 'wheat-ale');
    }, 3000);
    let svgPanZoom: SvgPanZoom.Instance = SvgPanZoom(this.svgRoot.nativeElement);
    this.cd.markForCheck();
  }

  setPath(target: string, parent: string) {
    this.removePath();
    this.openBar(this.currentOpenBar);
    this.svgRoot.nativeElement.classList.add('beer-map', 'active', target, parent);
  }

  removePath() {
    this.svgRoot.nativeElement.removeAttribute('class');
    this.openBar(this.currentOpenBar);
  }

  openBar(className: string) {
    this.closeOpenBar();

    document.querySelectorAll(`.${className}`).forEach(element => {
      element.classList.add('visible-bar');
    });
    this.svgRoot.nativeElement.classList.add(`${className}-active`);
    this.currentOpenBar = className;
    this.cd.markForCheck();
  }

  private closeOpenBar(): void {
    this.svgRoot.nativeElement.classList.remove(`${this.currentOpenBar}-active`);
    document.querySelectorAll('.visible-bar').forEach(value => {
      value.classList.remove('visible-bar');
    });
  }
}
