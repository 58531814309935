import { Roles } from '../security/model/roles';
import { MenuModel } from './model/menuModelPO';

export const PAGES: MenuModel[] = [
  {
    title: 'dashboard',
    url: '/',
    urlParams: true,
    icon: 'dashboard',
    menuItemPosition: 1,
    roles: [Roles.bronze, Roles.bartender, Roles.owner, Roles.admin]
  },
  {
    title: 'reports',
    url: '/reports',
    roles: [Roles.bartender, Roles.owner, Roles.admin],
    icon: 'reports',
    menuItemPosition: 1
  },
  {
    title: 'venue-management',
    url: '/venue-management',
    roles: [Roles.owner, Roles.admin],
    icon: 'venue-management',
    menuItemPosition: 1
  },
  {
    title: 'customer-management',
    url: '/customer-management',
    roles: [Roles.admin],
    icon: 'customer-management',
    menuItemPosition: 1
  },
  {
    title: 'settings',
    url: '/settings',
    roles: [Roles.bronze, Roles.bartender, Roles.owner, Roles.admin],
    icon: 'settings',
    menuItemPosition: 1
  },
];
