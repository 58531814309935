import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { PreloaderService } from '../../../shared/services/preloader.service';
import { AuthService } from './auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService,
              private preloader: PreloaderService,
              private router: Router) {
  }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.accessToken$.pipe(
      filter(token => typeof token === 'string'),
      mergeMap(token => {
        const tokenReq = req.clone({
          setHeaders: {Authorization: `Bearer ${token}`}
        });
        return next.handle(tokenReq);
      }),
      catchError(err => {
        this.preloader.hide();
        return throwError(err);
      })
    );
  }
}

