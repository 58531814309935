import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BeerRestService, VenueRestService } from '../../core/rest';
import { BeerPO } from '../../core/rest/model/beerPO';
import { VenuePO } from '../../core/rest/model/venuePO';
import { VenueService } from '../../core/services/venue.service';
import { Step } from '../../core/utils/step';
import { PreloaderService } from '../../shared/services/preloader.service';

@Component({
  selector: 'app-beer-list-modal',
  templateUrl: 'beer-list-modal.html',
  styleUrls: ['./beer-list-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeerListModalComponent implements OnInit {
  beerList: BeerPO[];
  formGroup: FormGroup;
  step: Step;
  venue: VenuePO;
  selectedBeer: BeerPO;
  currentPage = 0;
  @ViewChild('beersRef', {static: true}) beerRef: ElementRef;
  private formSubscription: Subscription;

  constructor(private dialogRef: MatDialogRef<BeerListModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private beerRestService: BeerRestService,
              private venueService: VenueService,
              private preloader: PreloaderService,
              private venueRestService: VenueRestService,
              private cd: ChangeDetectorRef) {
    this.prepareFormGroup();
  }

  ngOnInit() {
    this.beerRestService.searchBeersByQuery('').subscribe((res: any) => {
      this.initializeFetchedResource(res);
    }, (error) => {
      this.cd.markForCheck();
    });
    this.getItems();
    this.cd.markForCheck();
  }

  getItems() {
    this.formSubscription = this.formGroup.get('beerType').valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(formValue => this.beerRestService.searchBeersByQuery(formValue))
      )
      .subscribe((res: any) => {
        this.initializeFetchedResource(res);
      });

  }

  private initializeFetchedResource(res: any) {
    this.currentPage = res.number;
    this.beerList = res.content;
    this.beerRef.nativeElement.scrollTop = 0;
    this.cd.markForCheck();
  }

  onScroll() {
    const controlValue = this.formGroup.get('beerType').value;
    let currentPage = this.currentPage;
    this.beerRestService.searchBeersByQuery(controlValue, ++currentPage).subscribe((res: any) => {
      this.currentPage = res.number;
      this.beerList.push(...res.content);
      this.cd.markForCheck();
    }, error => {
      this.cd.markForCheck();
    });
    this.cd.markForCheck();
  }

  selectBeerType(selectedBeer: BeerPO) {
    this.selectedBeer = selectedBeer;
    this.cd.markForCheck();
  }


  dismiss(close) {
    if (close) {
      this.dialogRef.close(close);
    } else {
      this.dialogRef.close(null);
    }
    this.formSubscription.unsubscribe();
  }

  get getSelectedBeer(): string {
    const value = this.selectedBeer;
    return value ? `(${value.name})` : '';
  }

  prepareFormGroup() {
    this.formGroup = new FormGroup({
      beerType: new FormControl('', Validators.required),
      pageNumber: new FormControl(0, Validators.required)
    });
  }

  stepBack() {
    this.dialogRef.close({back: true});
  }

  goNextStep() {
    this.dismiss(this.selectedBeer);
    this.cd.markForCheck();
  }

}
