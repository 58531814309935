import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-edit-tap-modal',
  templateUrl: 'edit-tap-modal.html',
  styleUrls: ['./edit-tap-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTapModalComponent {
  managementTapIt: number;

  constructor(private dialogRef: MatDialogRef<EditTapModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {

    if (this.data) {
      this.managementTapIt = data.id;
      this.cd.markForCheck();
    }
  }

  dismiss(newData: boolean) {
    this.dialogRef.close(newData);
  }
}
