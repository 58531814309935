import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { map, switchMap, take } from 'rxjs/operators';
import { PAGES } from './core/pages/pages';
import { VenueRestService } from './core/rest';
import { VenuePO } from './core/rest/model/venuePO';
import { Roles } from './core/security/model/roles';
import { AuthService } from './core/security/services/auth.service';
import { LanguageService } from './core/services/language.service';
import { VenueService } from './core/services/venue.service';
import { SupportFormModalComponent } from './modals/support-form-modal/support-form-modal.component';
import { MenuService } from './shared/services/menu.service';
import { PreloaderService } from './shared/services/preloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  pages = PAGES;
  menu: boolean;
  venues: VenuePO[] = [];
  selectedVenue: VenuePO;
  SWIPE_ACTION = {LEFT: 'swipeleft', RIGHT: 'swiperight'};
  user: any;
  supportButtonClose = false;

  constructor(private logger: NGXLogger,
              private router: Router,
              private venueService: VenueService,
              private route: ActivatedRoute,
              private menuService: MenuService,
              private preloader: PreloaderService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private languageService: LanguageService,
              private venueRestService: VenueRestService,
              public authService: AuthService) {
    this.logger.trace('App component: constructor');
    translate.setDefaultLang('en');
    this.preloader.show(true);
    this.setActiveMenuUrl();
  }

  async ngOnInit() {
    this.logger.info('App component: ngOnInit');
    await this.initServices();
  }


  private async initServices() {
    this.authService.localAuthSetup();
    this.authService.userProfile$.subscribe(user => {
      this.user = user;
    });

    this.menuService.menuOpen.subscribe((menu: boolean) => {
      if (menu) {
        document.querySelector('body').classList.add('menu-active');
      } else {
        document.querySelector('body').classList.remove('menu-active');
      }
      this.menu = menu;
    });
    this.languageService.currentLanguage.subscribe((language: string) => {
      this.translate.setDefaultLang(language);
    });
    this.authService.userRoles.subscribe(async (roles: any) => {
      if (roles && roles.length !== 0) {
        this.venues = await this.venueRestService.getVenueList().pipe(take(1)).toPromise();
        this.selectedVenue = await this.venueService.getSelectedVenue();
        if (this.venues && this.venues.length === 0 && roles && roles[0] === Roles.bronze) {
          this.createFirstVenueForNewBronzeUser();
        } else if (this.selectedVenue === null) {
          this.router.navigateByUrl('error');
        }
      }
    });
    this.preloader.hide();
  }

  private createFirstVenueForNewBronzeUser() {
    this.venueRestService.createDefaultVenue().pipe(
      map(venue => {
        this.selectedVenue = venue;
      }),
      switchMap(() => this.venueRestService.getVenueList())
    ).subscribe(venueList => {
      this.venues = venueList;
      this.venueService.setCurrentVenue(this.selectedVenue);
    });
  }

  private setActiveMenuUrl() {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.pages.map(p => {
          const baseUrlEvent = event.url.split('/');
          const queryParamIndex = baseUrlEvent[1].indexOf('?');
          if (queryParamIndex === 0) {
            return p.active = '/' === p.url;
          } else if (baseUrlEvent.length >= 0) {
            if (queryParamIndex === -1) {
              return p.active = ('/' + baseUrlEvent[1]) === p.url;
            } else {
              return p.active = ('/' + baseUrlEvent[1].slice(0, queryParamIndex) === p.url);
            }
          }
        });
      }
    });
  }

  changeVenue(event) {
    const selectedvenue = event.value;
    if (selectedvenue !== this.selectedVenue.id) {
      const newVenue = this.venues.find(venue => venue.id === selectedvenue);
      this.venueService.setCurrentVenue(newVenue);
      this.selectedVenue = newVenue;
      this.closeMenu();
    }
  }

  swipe(event, eType: any) {
    if (eType === this.SWIPE_ACTION.RIGHT) {
      const endPoint = event.changedPointers[0].pageX;
      const distance = event.distance;
      const origin = endPoint - distance;
      if (origin <= 15) {
        this.openMenu();
      }
    } else if (eType === this.SWIPE_ACTION.LEFT) {
      this.closeMenu();
    }
  }

  logout() {
    this.preloader.show(true);
    this.authService.logout();
  }

  openMenu() {
    if (this.menu === false) {
      this.menuService.openMenu();
    }
  }

  closeMenu() {
    if (this.menu === true) {
      this.menuService.closeMenu();
    }
  }

  openSupportModal() {
    this.supportButtonClose = !this.supportButtonClose;
    const dialogRef = this.dialog.open(SupportFormModalComponent, {
      width: '360px',
      disableClose: false,
      hasBackdrop: false,
      panelClass: 'support-form-modal',
      backdropClass: 'backdrop-transparent',
      data: {
        // information: informationText,
        // user: 'Jan Nowak'
      },
      scrollStrategy: new NoopScrollStrategy()
    });
    const el = document.querySelector('.viewport-box').classList.add('disable-blur');
    dialogRef.afterClosed().subscribe(res => {
      this.supportButtonClose = !this.supportButtonClose;
    });
  }
}
