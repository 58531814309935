import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

const localeKey = 'locale';

@Injectable()
export class LanguageService {
  currentLanguage$: BehaviorSubject<string> = new BehaviorSubject('en');

  constructor(
    private logger: NGXLogger) {
    this.logger.trace('LanguageService running');
  }

  setCurrentLanguage(locale: string): void {
    localStorage.setItem(localeKey, locale);
    this.currentLanguage$.next(locale);
    this.logger.info('Language changed:', locale);
  }

  get currentLanguage(): BehaviorSubject<string> {
    const res = localStorage.getItem(localeKey);
    if (res) {
      this.currentLanguage$.next(res);
    }
    return this.currentLanguage$;
  }
}
