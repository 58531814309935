import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { BeerRestService, CalibrationRestService, TapRestService } from '../../core/rest';
import { BeerPO } from '../../core/rest/model/beerPO';
import { PourPO } from '../../core/rest/model/pourPO';
import { CalibrationStatus, TapPO } from '../../core/rest/model/tapPO';
import { Step } from '../../core/utils/step';
import { KegSizeModalComponent } from '../keg-size-modal/keg-size-modal.component';

@Component({
  selector: 'app-calibration-flow-modal',
  templateUrl: 'calibration-modal.html',
  styleUrls: ['./calibration-modal.scss']
})
export class CalibrationModalComponent implements OnInit, AfterViewInit {
  step: Step;

  @ViewChild('stepper', {static: false})
  stepper: any;
  calibrateFormGroup: FormGroup;
  calibrationValueFormGroup: FormGroup;
  calibrationPour: PourPO;
  pourSubscription: Subscription;
  showCalibrationRecord = false;
  disableButton = false;
  calibrationComplete = false;
  tapId: number;
  selectedBeer: BeerPO;
  calibrationStatus: CalibrationStatus;
  kegSize: number;
  formGroup: FormGroup;
  kegAttached = false;
  calibrationPressed = false;
  kegDetached: boolean;
  calibrationDocId: string;


  constructor(private activatedRoute: ActivatedRoute,
              private dialogRef: MatDialogRef<KegSizeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private beerRestService: BeerRestService,
              private tapRestService: TapRestService,
              private calibrationRestService: CalibrationRestService,
              private angularFirestore: AngularFirestore,
              private formBuilder: FormBuilder,
              private logger: NGXLogger,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initServices();
  }

  ngAfterViewInit(): void {
    this.subscribePourDocument();
    this.cd.detectChanges();
  }

  private initServices() {
    if (this.data) {
      this.step = this.data.step;
      this.selectedBeer = this.data.selectedBeer;
      this.kegSize = this.data.kegSize;
      this.tapId = this.data.tapId;
      this.calibrationStatus = this.data.calibrationStatus;
      this.kegDetached = this.data.kegDetached;
      this.calibrationDocId = this.data.calibrationDocId;
    }

    this.formGroup = new FormGroup({
        realValue: new FormControl(null, [Validators.min(1), Validators.required])
      }
    );
    this.calibrateFormGroup = this.formBuilder.group({
      calibrated: ['', Validators.required]
    });
    this.calibrationValueFormGroup = this.formBuilder.group({
      isValue: ['', Validators.required]
    });
    this.cd.markForCheck();
  }

  private getFirestoreCollection(tapId: number): AngularFirestoreCollection<PourPO> {
    return this.angularFirestore.collection('events')
      .doc('calibration')
      .collection(String(tapId));
  }

  finishCalibration() {
    const realValue = +this.formGroup.get('realValue').value;
    if (realValue && realValue > 0) {
      this.calibrationRestService.updateRealValue(this.calibrationPour.tapId, realValue).subscribe((response: TapPO) => {
        this.formGroup.reset();
        this.dismiss(response);
        this.stepperNext();
        this.cd.markForCheck();
      }, (error) => {
        console.log('Error in calibration modal: ', error);
        this.cd.markForCheck();
      });
    }
  }

  dismiss(currentTap: TapPO) {
    this.dialogRef.close(currentTap);
  }

  startCalibrate(): void {
    this.calibrationRestService.calibrate(this.tapId).subscribe(() => {
      this.calibrateFormGroup.patchValue({calibrated: true});
      this.calibrationComplete = false;
      this.calibrationPressed = true;
      this.showCalibrationRecord = false;
      this.calibrationStatus = CalibrationStatus.WAITING;
      this.stepperNext();
      this.cd.markForCheck();
    }, (error) => {
      console.log('Error in calibration modal: ', error);
      this.cd.markForCheck();
    });

    this.subscribePourDocument();
  }


  private subscribePourDocument() {
    this.pourSubscription = this.getFirestoreCollection(this.tapId).doc(this.calibrationDocId).valueChanges().subscribe((pour: PourPO) => {
      if (pour) {
        this.calibrationPour = pour;
        this.calibrationPour.tapId = this.tapId;
        this.showCalibrationRecord = true;
        this.disableButton = false;
        this.cd.markForCheck();
      } else {
        this.disableButton = true;
        // this.showCalibrationRecord = false;
        this.cd.markForCheck();
      }
    });
  }

  stepperNext(): void {
    this.stepper.next();
  }


  private moveToCheckCalibrationStep() {
    this.calibrateFormGroup.patchValue({calibrated: true});
    this.stepper.selectedIndex = 1;
    this.stepper.selectedIndex = 2;
  }

  private goToStepBasedOnCalibrationStatus(calibrationStatus: CalibrationStatus) {
    switch (calibrationStatus) {
      case CalibrationStatus.WAITING:
        this.moveToCheckCalibrationStep();
        this.subscribePourDocument();
        break;
      case CalibrationStatus.NOT_CALIBRATED:
        this.stepper.selectedIndex = 0;
        break;
      case CalibrationStatus.NO_VALUE:
        this.moveToCheckCalibrationStep();
        this.subscribePourDocument();
        break;
      case CalibrationStatus.CALIBRATED:
        break;
      case CalibrationStatus.RECALIBRATED:
        break;
    }
  }

  get disableCalibrationButton() {
    return this.calibrationStatus === CalibrationStatus.NO_VALUE || this.calibrationStatus === CalibrationStatus.WAITING;
  }
}
