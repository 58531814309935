import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerManagementComponent } from './components/customer-management/customer-management.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataDrawingComponent } from './components/data-drawing/data-drawing.component';
import { ErrorComponent } from './components/error/error.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BeerRaceComponent } from './components/report/components/beer-race/beer-race.component';
import { ReportComponent } from './components/report/report.component';
import { TapDetailComponent } from './components/tap-detail/tap-detail.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { BeerMananagementComponent } from './components/venue-management/components/beer-mananagement/beer-mananagement.component';
import { OrdersComponent } from './components/venue-management/components/orders/orders.component';
import { TapsManagementComponent } from './components/venue-management/components/taps-management/taps-management.component';
import { UserMananagementComponent } from './components/venue-management/components/user-mananagement/user-mananagement.component';
import { VenueManagementComponent } from './components/venue-management/venue-management.component';
import { CoreModule } from './core/core.module';
import { CallbackPageComponent } from './core/security/component/callback-page.component';
import { InterceptorService } from './core/security/services/token.interceptor';
import { ModalsModule } from './modals/modals.module';
import { SettingsComponent } from './settings/settings.component';
import { PreloaderComponent } from './shared/components/preloader/preloader.component';
import { SharedModule } from './shared/shared.module';


export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {velocity: 0.4, threshold: 20},
    pinch: {enable: false},
    rotate: {enable: false}
  } as any;
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CallbackPageComponent,
    MenuItemComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    ReportComponent,
    TapDetailComponent,
    VenueManagementComponent,
    BeerRaceComponent,
    UserMananagementComponent,
    BeerMananagementComponent,
    PreloaderComponent,
    SettingsComponent,
    ErrorComponent,
    TapsManagementComponent,
    PageNotFoundComponent,
    CustomerManagementComponent,
    DataDrawingComponent,
    OrdersComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false
    }),
    ModalsModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
