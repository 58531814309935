import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../shared/shared.module';
import { BeerListModalComponent } from './beer-list-modal/beer-list-modal.component';
import { CalibrationModalComponent } from './calibration-modal/calibration-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ConfirmationProcessModalComponent } from './customer-management-modals/confirmation-process-modal/confirmation-process-modal.component';
import { NewCustomerModalComponent } from './customer-management-modals/new-customer-modal/new-customer-modal.component';
import { NewTapsModalComponent } from './customer-management-modals/new-taps-modal/new-taps-modal.component';
import { NewVenueModalComponent } from './customer-management-modals/new-venue-modal/new-venue-modal.component';
import { EditTapModalComponent } from './edit-tap-modal/edit-tap-modal.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { InviteUserModalComponent } from './invite-user-modal/invite-user-modal.component';
import { KegSizeModalComponent } from './keg-size-modal/keg-size-modal.component';
import { KegSummaryModalComponent } from './keg-summary-modal/keg-summary-modal.component';
import { RemoveUserModalComponent } from './remove-user-modal/remove-user-modal.component';
import { SupportFormModalComponent } from './support-form-modal/support-form-modal.component';

@NgModule({
  declarations: [
    BeerListModalComponent,
    KegSizeModalComponent,
    CalibrationModalComponent,
    KegSummaryModalComponent,
    ConfirmationModalComponent,
    InviteUserModalComponent,
    EditUserModalComponent,
    RemoveUserModalComponent,
    EditTapModalComponent,
    NewCustomerModalComponent,
    NewVenueModalComponent,
    NewTapsModalComponent,
    ConfirmationProcessModalComponent,
    SupportFormModalComponent
  ],
  entryComponents: [
    BeerListModalComponent,
    KegSizeModalComponent,
    CalibrationModalComponent,
    KegSummaryModalComponent,
    ConfirmationModalComponent,
    InviteUserModalComponent,
    EditUserModalComponent,
    RemoveUserModalComponent,
    EditTapModalComponent,
    NewCustomerModalComponent,
    NewVenueModalComponent,
    NewTapsModalComponent,
    ConfirmationProcessModalComponent,
    SupportFormModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    InfiniteScrollModule
  ],
  exports: [
    BeerListModalComponent,
    KegSizeModalComponent,
    CalibrationModalComponent,
    KegSummaryModalComponent,
    ConfirmationModalComponent,
    InviteUserModalComponent,
    EditTapModalComponent,
    NewCustomerModalComponent,
    NewVenueModalComponent,
    NewTapsModalComponent,
    ConfirmationProcessModalComponent,
    SupportFormModalComponent
  ],
  providers: []
})
export class ModalsModule {
}
