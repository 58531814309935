import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback-page.component.html',
  styleUrls: ['./callback-page.component.scss']
})
export class CallbackPageComponent implements OnInit {

  constructor(private authService: AuthService,
              private logger: NGXLogger,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('error') === 'unauthorized') {
      this.authService.logout();
    } else {
      this.authService.handleAuthCallback();
    }
  }
}
