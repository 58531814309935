import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-barrel-level',
  templateUrl: './barrel-level.component.html',
  styleUrls: ['./barrel-level.component.scss']
})
export class BarrelLevelComponent implements OnInit, OnChanges {

  @Input()
  kegUsed: number;

  @Input()
  kegSize: number;

  @Input()
  icon: boolean;

  @Input()
  isDetached: boolean;

  @Input()
  maxWidth: number;

  @Input()
  width: number;

  @Input()
  onlineFlag: boolean;

  hidden = true;

  imgSrc: string;

  imgPath = '../../../../assets/keg_stage/';

  constructor(private logger: NGXLogger,
              private cd: ChangeDetectorRef) {

  }

  ngOnInit() {

  }

  ngOnChanges(): void {
    this.setBarrelLevel();
  }

  public setBarrelLevel() {
    if (this.isDetached) {
      this.imgSrc = this.imgPath + 'unlinked.svg';
    } else if (this.kegUsed === 0) {
      this.imgSrc = this.imgPath + 'stage8.svg';
    } else {
      const currentStage = Math.ceil((this.kegUsed / this.kegSize) * 8);
      if (!Number.isNaN(currentStage) && currentStage > 0 && currentStage < 10) {
        if ((1 - (this.kegUsed / this.kegSize)) <= 0.07) {
          this.imgSrc = this.imgPath + 'stageAlert.svg';
        } else {
          this.imgSrc = this.imgPath + `stage${9 - currentStage}.svg`;
        }
      } else {
        this.imgSrc = this.imgPath + `stageAlert.svg`;
      }
    }
  }

  imageLoaded() {
    this.hidden = false;
  }
}
