import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MenuService {
  private menuOpenState = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  get menuOpen(): BehaviorSubject<boolean> {
    return this.menuOpenState;
  }

  toggleMenu() {
    this.menuOpenState.next(!this.menuOpenState.value);
  }

  closeMenu() {
    this.menuOpenState.next(false);
  }

  openMenu() {
    this.menuOpenState.next(true);
  }
}
