import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../core/services/language.service';
import { UnitService } from '../core/services/unit.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  currentLanguage: any;
  unit: any;

  constructor(private languageService: LanguageService,
              private unitService: UnitService) {
  }

  ngOnInit() {
    this.languageService.currentLanguage.subscribe((language: string) => {
      this.currentLanguage = language;
    });
    this.unitService.unit.subscribe((unit: string) => {
      this.unit = unit;
    });
  }

  changeLanguage(language: string) {
    this.languageService.setCurrentLanguage(language);
  }

  changeUnit(unit: string) {
    this.unitService.setUnit(unit);
  }
}
