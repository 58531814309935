import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { VenuePO } from '../model/venuePO';

@Injectable()
export class VenueRestService {

  protected basePath = environment.baseUrl;

  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {
  }

  public getVenueList(): Observable<VenuePO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/venue`,
      {
        headers
      }
    );
  }

  public createDefaultVenue(): Observable<VenuePO> {
    const headers = this.defaultHeaders;

    return this.httpClient.put<any>(`${this.basePath}/venue`,
      {
        headers
      }
    );
  }
}
