import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CalibrationRestService {

    protected basePath = environment.baseUrl;

    public defaultHeaders = new HttpHeaders();

    constructor(protected httpClient: HttpClient) {
    }

    public calibrate(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.httpClient.post<any>(`${this.basePath}/tap/${id}/calibrate`,
            null,
            {
                headers,
                responseType: 'text' as 'json'
            }
        );
    }

    public updateRealValue(id: number, realValue: number): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter tapId was null or undefined when calling calibrate.');
        }
        const headers = this.defaultHeaders;


        return this.httpClient.post<number>(`${this.basePath}/tap/${encodeURIComponent(String(id))}/calibrate/real`,
            realValue,
            {
                headers
            }
        );
    }

}
