import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TapPO } from '../../core/rest/model/tapPO';
import { Roles } from '../../core/security/model/roles';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-invite-user-modal',
  templateUrl: 'invite-user-modal.html',
  styleUrls: ['./invite-user-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteUserModalComponent {
  roles = Object.keys(Roles).map(value => value);
  currentTap: TapPO;
  formGroup: FormGroup;

  constructor(private dialogRef: MatDialogRef<InviteUserModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {
    this.prepareFormGroup();
    this.cd.markForCheck();
  }

  dismiss(data: boolean) {
    if (data) {
      this.dialogRef.close(this.formGroup.getRawValue());
    } else {
      this.dialogRef.close(false);
    }
  }

  prepareFormGroup(): void {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern(Utils.emailValidator)]),
      role: new FormControl('', Validators.required)
    });
  }
}
