import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AttachKeg } from '../../utils/attachKeg';
import { DashboardPO } from '../model/dashboardPO';
import { ManagementTapPO } from '../model/managementTapPO';
import { TapPO, TapReportPO } from '../model/tapPO';

@Injectable()
export class TapRestService {

  protected readonly basePath = environment.baseUrl;

  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {
  }

  public getDashboardElements(venue: string): Observable<DashboardPO> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/tap/dashboard?venue=${venue}`,
      {
        headers
      }
    );
  }

  public getReportTapList(venue: string): Observable<TapReportPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/report/tap?venue=${venue}`,
      {
        headers
      }
    );
  }

  public getTapDetails(id: number): Observable<TapPO> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/tap/${id}`,
      {
        headers
      }
    );
  }

  public getTapsManagementList(venueId: string): Observable<ManagementTapPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.get<any>(`${this.basePath}/tap/management?venue=${venueId}`,
      {
        headers
      }
    );
  }

  public detachKeg(tapId: number): Observable<any> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/tap/${tapId}/detach`,
      null,
      {
        headers
      }
    );
  }

  public attachKeg(tapId: number, attachKeg: AttachKeg): Observable<any> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/tap/${tapId}/attach`,
      attachKeg,
      {
        headers
      }
    );
  }

  public renewKeg(tapId: number): Observable<any> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/tap/${tapId}/renew`,
      {
        headers
      }
    );
  }

  deactivateMaintenance(tapId: number) {
    const headers = this.defaultHeaders;

    return this.httpClient.put<any>(`${this.basePath}/tap/management/${tapId}/deactivate-maintenance`,
      {
        headers
      }
    );
  }

  activateMaintenance(tapId: number) {
    const headers = this.defaultHeaders;

    return this.httpClient.put<any>(`${this.basePath}/tap/management/${tapId}/activate-maintenance`,
      {
        headers
      }
    );
  }
}
