import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../security/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.authService.isAuthenticated$.pipe(
      tap(async loggedIn => {
        const user = await this.authService.getUser$().toPromise();
        const hasPermission = this.authService.hasPermission(route.data.roles, user ? user['https://tapitsolutions.com/roles'] : null);

        if (loggedIn) {
          if (hasPermission === true) {
            return true;
          } else if (this.authService.getRoles() && this.authService.getRoles().length === 0) {
            this.router.navigate(['error']);
            return false;
          } else if (hasPermission === false) {
            this.router.navigateByUrl('/forbidden');
            return false;
          }
        } else {
          this.authService.login(state.url);
        }
      })
    );
  }
}

