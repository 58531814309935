import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { OrderRestService } from '../../core/rest/api/orderRest.service';
import { OrderPO } from '../../core/rest/model/orderPO';
import { OrderStatusPO } from '../../core/rest/model/orderStatusPO';
import { ConfirmationProcessModalComponent } from '../../modals/customer-management-modals/confirmation-process-modal/confirmation-process-modal.component';
import { NewCustomerModalComponent } from '../../modals/customer-management-modals/new-customer-modal/new-customer-modal.component';
import { NewTapsModalComponent } from '../../modals/customer-management-modals/new-taps-modal/new-taps-modal.component';
import { NewVenueModalComponent } from '../../modals/customer-management-modals/new-venue-modal/new-venue-modal.component';
import { PreloaderService } from '../../shared/services/preloader.service';

@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerManagementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['orderId', 'orderStartDate', 'customerName', 'invoiceData', 'venueName', 'numberOfTaps', 'orderStatus', 'paymentStatus', 'action'];
  dataSource;
  orders: OrderStatusPO[];

  constructor(private dialog: MatDialog,
              private orderRestService: OrderRestService,
              private preloader: PreloaderService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.preloader.show();
    this.orderRestService.retrieveOrders().subscribe((orders: OrderStatusPO[]) => {
      this.dataSource = new MatTableDataSource(orders);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.preloader.hide();
      this.cd.markForCheck();
    }, error => {
      this.preloader.hide();
    });
  }

  ngAfterViewInit(): void {
  }

  applyFilter(filterValue: string) {
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    if (this.dataSource && this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openNewCustomerModal() {
    const dialogRef = this.dialog.open(NewCustomerModalComponent, {
      width: '800px',
      disableClose: true,
      maxHeight: '100vh'
    });
    this.prepareOrder(dialogRef, OrderType.FULL);
  }

  private prepareOrder(dialogRef: MatDialogRef<any>, orderType: OrderType) {
    let closeResult;
    dialogRef.afterClosed()
      .pipe(
        tap(() => this.preloader.show()),
        map(res => res),
        mergeMap(result => {
          closeResult = result;
          if (result === false) {
            return of(null);
          } else if (orderType === OrderType.FULL) {
            return this.orderRestService.startFullOrderProcess(this.mapFormToPO(result));
          } else if (orderType === OrderType.VENUE) {
            return this.orderRestService.startOrderProcessForVenue(this.mapFormToPO(result));
          } else if (orderType === OrderType.TAPS) {
            return this.orderRestService.startOrderProcessForTaps(this.mapFormToPO(result));
          }
        }),
        mergeMap(() => {
          if (closeResult !== false) {
            return this.orderRestService.retrieveOrders();
          } else {
            return of(null);
          }
        })
      ).subscribe((orders: OrderStatusPO[]) => {
      if (orders !== null) {
        this.dataSource = new MatTableDataSource(orders);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }

  openNewVenueModal() {
    const dialogRef = this.dialog.open(NewVenueModalComponent, {
      width: '800px',
      disableClose: true,
      maxHeight: '100vh'
    });

    this.prepareOrder(dialogRef, OrderType.VENUE);
  }

  openNewTapsModal() {
    const dialogRef = this.dialog.open(NewTapsModalComponent, {
      width: '800px',
      disableClose: true,
      maxHeight: '100vh'
    });

    this.prepareOrder(dialogRef, OrderType.TAPS);
  }

  openConfirmationModal(informationText: string) {
    const dialogRef = this.dialog.open(ConfirmationProcessModalComponent, {
      width: '600px',
      disableClose: true,
      backdropClass: 'backdrop-blur',
      data: {
        information: informationText,
        user: 'Jan Nowak'
      }
    });

    return dialogRef.afterClosed();
  }

  mapFormToPO(formRawData): OrderPO {
    return {
      customerName: formRawData.name,
      customerEmail: formRawData.email,
      venueName: formRawData.venueName,
      venueAddress: formRawData.address,
      deliveryAddress: formRawData.isOtherAddress ? formRawData.otherAddress : '',
      invoiceDetails: formRawData.invoiceDetails,
      numberOfTaps: +formRawData.tapsNumber
    };
  }

  getDate(orderStartDate: string) {
    return orderStartDate.slice(0, 10);
  }

  completeOrderTask(process: string, orderStatus: string) {
    this.openConfirmationModal(`Go forward with ${orderStatus}`)
      .pipe(
        map(result => result),
        mergeMap((closeResult: boolean) => {
          if (closeResult) {
            return this.completeAction(process, orderStatus);
          } else {
            return of(null);
          }
        })
      ).subscribe((orders: OrderStatusPO[]) => {
      if (orders !== null) {
        this.dataSource = new MatTableDataSource(orders);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.cd.markForCheck();
      }
      this.preloader.hide();
    }, (error: HttpErrorResponse) => {
      alert(`${error.status}, ${error.message}`);
    });
  }

  completeAction(process: string, orderStatus: string) {
    this.preloader.show();
    return this.orderRestService.completeOrderTask(process, orderStatus).pipe(
      mergeMap(() => {
        return this.orderRestService.retrieveOrders();
      })
    );
  }
}

enum OrderType {
  FULL,
  VENUE,
  TAPS
}
