import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PreloaderService } from '../../shared/services/preloader.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private preloader: PreloaderService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.preloader.hide();
  }
}
