import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SupportPO } from '../model/supportPO';
import { UsedBeerPO } from '../model/usedBeerPO';


@Injectable()
export class SupportRestService {

  protected basePath = environment.baseUrl;

  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {
  }

  public createSupportTicket(supportObject: SupportPO): Observable<UsedBeerPO[]> {
    const headers = this.defaultHeaders;

    return this.httpClient.post<any>(`${this.basePath}/support`,
      supportObject,
      {
        headers
      }
    );
  }

}
