import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { shareReplay, take } from 'rxjs/operators';
import { VenueRestService } from '../rest';
import { VenuePO } from '../rest/model/venuePO';

const selectedVenue = 'venue';

@Injectable()
export class VenueService {
  private selectedVenue: VenuePO;

  constructor(private venueRestService: VenueRestService,
              private route: ActivatedRoute,
              private router: Router,
              private logger: NGXLogger) {
    this.logger.trace('VenueService running');

  }

  setCurrentVenue(venue: VenuePO): void {
    this.selectedVenue = venue;
    this.redirectChangeVenue();
  }

  private redirectChangeVenue() {
    let url = [];
    if (this.router.url.indexOf('callback') === 1) {
      this.router.navigate(url);
    } else {
      if (this.router.url.indexOf('tap') === 1) {
        url = ['/'];
      }
      this.router.navigate(url, {
        queryParams: {venue: this.selectedVenue.id}
      });
    }
  }

  async getSelectedVenue(): Promise<VenuePO> {
    const venueList = await this.venueRestService.getVenueList().pipe(take(1)).toPromise();
    const venue = this.route.snapshot.queryParamMap.get('venue');
    const venueElement = venueList.find(value => value.id === venue);
    if (venueElement) {
      this.selectedVenue = venueElement;
      return this.selectedVenue;
    } else {
      return this.setDefaultVenue(venueList);
    }
  }

  private setDefaultVenue(venueList) {
    if (venueList && venueList.length > 0) {
      this.setCurrentVenue(venueList[0]);
      return venueList[0];
    } else {
      return null;
    }
  }
}
