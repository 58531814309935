import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSliderModule,
  MatSnackBar,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule
} from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    MatSliderModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatIconModule,
    MatSelectModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule
  ],
  exports: [
    MatSliderModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatIconModule,
    MatSelectModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule
  ],
  providers: [MatSnackBar]
})

export class MaterialModule {
}
