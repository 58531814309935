import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { SupportRestService } from '../../core/rest/api/supportRest.service';
import { PreloaderService } from '../../shared/services/preloader.service';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-support-form-modal',
  templateUrl: 'support-form-modal.html',
  styleUrls: ['./support-form-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportFormModalComponent implements OnInit {
  beerName: string;
  supportFormGroup: FormGroup;
  supportCategories = [
    {
      type: 'Bug',
      languageKey: 'bug-report'
    }, {
      type: 'Technical',
      languageKey: 'technical-question'
    }, {
      type: 'Feedback',
      languageKey: 'general-feedback'
    }, {
      type: 'Proposal',
      languageKey: 'functional-proposal'
    }
  ];
  private formSubscription: Subscription;
  ticketError = false;
  isSend = false;

  constructor(private dialogRef: MatDialogRef<SupportFormModalComponent>,
              private supportRestService: SupportRestService,
              private preloaderService: PreloaderService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {
    this.prepareFormGroup();
  }

  ngOnInit(): void {
    this.restoreFormAfterMinimize();
    this.formSubscription = this.supportFormGroup.valueChanges.subscribe(form => {
      this.saveMinimizeForm();
    });
  }

  closeModal(minimize: boolean): void {
    // Lines 54 & 55 to remove.
    if (minimize && this.isSend === false) {
      this.saveMinimizeForm();
    }
    this.dialogRef.close();
    this.formSubscription.unsubscribe();
  }

  private saveMinimizeForm(): void {
    const form = this.supportFormGroup.getRawValue();
    localStorage.setItem('form', JSON.stringify(form));
  }

  private restoreFormAfterMinimize(): void {
    const form = localStorage.getItem('form');
    if (form) {
      this.supportFormGroup.patchValue(JSON.parse(form));
      this.cd.markForCheck();
    }
  }

  fileChange(event: any) {
    const fileList: FileList = event.target.files;
    console.log(fileList);
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);

    }
  }

  submitTicket(): void {
    this.preloaderService.show();
    this.supportRestService.createSupportTicket(this.supportFormGroup.getRawValue()).subscribe(res => {
      this.changeStateAfterSuccessfulSend();
    }, error => {
      this.ticketError = true;
      this.cd.markForCheck();
      this.preloaderService.hide();
    });
  }

  private changeStateAfterSuccessfulSend(): void {
    this.isSend = true;
    localStorage.removeItem('form');
    setTimeout(() => {
      this.closeModal(false);
    }, 8000);
    this.preloaderService.hide();
    this.cd.markForCheck();
  }

  prepareFormGroup(): void {
    this.supportFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Utils.emailValidator)]),
      type: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      text: new FormControl('', [Validators.required, Validators.maxLength(1000)])
    });
  }
}
