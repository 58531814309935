import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { asyncScheduler, Subscription } from 'rxjs';
import { flatMap, throttleTime } from 'rxjs/operators';
import { TapRestService } from '../../core/rest';
import { BeerPO } from '../../core/rest/model/beerPO';
import { DashboardTapPO } from '../../core/rest/model/dashboardTapPO';
import { CalibrationStatus, TapPO, TapPourPO } from '../../core/rest/model/tapPO';
import { VenuePO } from '../../core/rest/model/venuePO';
import { VenueService } from '../../core/services/venue.service';
import { PreloaderService } from '../../shared/services/preloader.service';

@Component({
  selector: 'app-tap-detail',
  templateUrl: './tap-detail.component.html',
  styleUrls: ['./tap-detail.component.scss']
})
export class TapDetailComponent implements OnInit, OnDestroy {
  currentTap: TapPO;
  previousTap: TapPO;
  selectedBeer: BeerPO;
  kegSize: number;
  lastPours: TapPourPO[];
  subscription: Subscription;
  tapId: number;
  selectedVenue: VenuePO;
  glassResource: string[];

  constructor(private activatedRoute: ActivatedRoute,
              private angularFirestore: AngularFirestore,
              private router: Router,
              private tapRestService: TapRestService,
              private dialog: MatDialog,
              private preloader: PreloaderService,
              private venueService: VenueService,
              private cd: ChangeDetectorRef,
              private logger: NGXLogger) {
    this.logger.trace('Tap Detail Page running');
    this.tapId = +this.activatedRoute.snapshot.paramMap.get('tapId');
    this.cd.markForCheck();
  }

  ngOnInit() {
    this.initServices();
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async initServices() {
    this.preloader.show();
    this.selectedVenue = await this.venueService.getSelectedVenue();
    this.tapRestService.getTapDetails(this.tapId).subscribe((tap: TapPO) => {
      if (tap.maintenance || tap.kegDetached || tap.calibrationStatus !== CalibrationStatus.CALIBRATED) {
        this.router.navigateByUrl('/', {queryParams: {venue: this.selectedVenue.id}});
      } else {
        this.currentTap = tap;
        this.lastPours = tap.lastPours;
        this.setGlassResource(tap);
      }

    });
    this.updateTapDetailsAfterPour(this.tapId);
  }


  private getFirestoreNotificationCollection(tapId: number): AngularFirestoreDocument<any> {
    return this.angularFirestore.collection('events').doc('pour_on_tap').collection(String(tapId)).doc('t');
  }

  private updateTapDetailsAfterPour(tapId: number) {
    this.subscription = this.getFirestoreNotificationCollection(this.tapId).valueChanges().pipe(
      throttleTime(10000, asyncScheduler, {
        leading: true,
        trailing: true
      }),
      flatMap(() => this.tapRestService.getTapDetails(tapId))
    ).subscribe((tap: TapPO) => {
      this.currentTap = tap;
      this.lastPours = tap.lastPours;
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }

  get hideElementWhenDetached() {
    if (this.currentTap) {
      return this.currentTap.kegDetached !== true;
    }
  }

  percentBarrelLevel(tapItem: DashboardTapPO): string {
    if (tapItem.kegDetached) {
      return '';
    } else {
      const result = (100 - Math.round((tapItem.kegUsed / tapItem.kegSize) * 100));
      if (!Number.isNaN(result) && result < 0) {
        return '0%';
      }
      if (!Number.isNaN(result)) {
        return result < 0 ? '100%' : String(result + '%');
      }
    }
    this.cd.markForCheck();
  }

  private setGlassResource(tap: TapPO) {
    if (tap && tap.beerInfo && tap.beerInfo.glass && typeof tap.beerInfo.glass === 'string') {
      this.glassResource = tap.beerInfo.glass.split(',').map((resource: string) => {
        if (resource.charAt(0) === ' ') {
          return resource.substring(1);
        } else {
          return resource;
        }
      });
      this.cd.markForCheck();
    }
  }
}
