import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VenuePO } from '../../core/rest/model/venuePO';
import { VenueService } from '../../core/services/venue.service';

@Component({
  selector: 'app-venue-management',
  templateUrl: './venue-management.component.html',
  styleUrls: ['./venue-management.component.scss']
})
export class VenueManagementComponent implements OnInit {
  selectedVenue: VenuePO;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private venueService: VenueService) {
  }

  async ngOnInit() {
    this.route.queryParamMap.subscribe(async () => {
      this.selectedVenue = await this.venueService.getSelectedVenue();
    });
  }

  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url :
    this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }
}
