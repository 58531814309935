import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as R from 'ramda';
import { Observable, zip } from 'rxjs';
import { ComponentCanDeactivate } from '../../../../core/guards/pending-changes.guard';
import { BeerRestService, VenueRestService } from '../../../../core/rest';
import { BeerPO } from '../../../../core/rest/model/beerPO';
import { VenuePO } from '../../../../core/rest/model/venuePO';
import { VenueService } from '../../../../core/services/venue.service';
import { PreloaderService } from '../../../../shared/services/preloader.service';
import { Utils } from '../../../../utils/utils';

@Component({
  selector: 'app-beer-mananagement',
  templateUrl: './beer-mananagement.component.html',
  styleUrls: ['./beer-mananagement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeerMananagementComponent implements OnInit, ComponentCanDeactivate {

  constructor(private beerRestService: BeerRestService,
              private venueRestService: VenueRestService,
              private preloader: PreloaderService,
              private route: ActivatedRoute,
              private venueService: VenueService,
              private cd: ChangeDetectorRef) {
  }

  beersDisplay: BeerPO[];
  selectedBeersDisplay = [];
  private venue: VenuePO;
  private initBeerList: BeerPO[];
  private initSelectedList: BeerPO[];


  ngOnInit() {
    this.initServices();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.checkIdentical();
  }

  private async initServices() {
    this.preloader.show();
    this.route.queryParamMap.subscribe(async () => {
      this.venue = await this.venueService.getSelectedVenue();

      if (this.venue) {
        zip(
          this.beerRestService.getAllBeerTypes(),
          this.beerRestService.getAvailableBeerTypes(this.venue.id)
        ).subscribe(([allBeers, availableBeers]) => {
          const identical = R.equals(allBeers, availableBeers);
          allBeers.sort((a, b) => a.name.localeCompare(b.name));
          availableBeers.sort((a, b) => a.name.localeCompare(b.name));

          if (identical) {
            this.beersDisplay = allBeers;
            this.initBeerList = allBeers;
            this.selectedBeersDisplay = [];
            this.initSelectedList = [];
            this.cd.markForCheck();
          } else {
            this.beersDisplay = allBeers.filter(beer => !R.includes(beer, availableBeers));
            this.initBeerList = allBeers;
            this.selectedBeersDisplay = availableBeers;
            this.initSelectedList = R.clone(availableBeers);
            this.cd.markForCheck();
          }
          this.preloader.hide();
        });
      } else {
        this.preloader.hide();
      }
    });
    this.cd.markForCheck();
  }

  drop(event: CdkDragDrop<BeerPO[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.cd.markForCheck();
  }

  searchAllItems(event: any) {
    this.beersDisplay = this.initBeerList ? this.initBeerList.filter(x => !R.includes(x, this.selectedBeersDisplay)) : [];
    const val = event.target.value;

    if (val && val.trim() !== '') {
      this.beersDisplay = this.beersDisplay.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      });
    }
    this.cd.markForCheck();
  }

  addToSelected(beer: BeerPO): void {
    this.selectedBeersDisplay.unshift(beer);
    Utils.removeItemFromArray(this.beersDisplay, beer);
    this.sortBeersList();
    this.cd.markForCheck();
  }

  removeFromSelected(beer: BeerPO): void {
    this.beersDisplay.unshift(beer);
    Utils.removeItemFromArray(this.selectedBeersDisplay, beer);
    this.sortBeersList();
    this.cd.markForCheck();
  }

  saveSelectedBeers() {
    const beersToSave = this.selectedBeersDisplay.map((beer: BeerPO) => {
      return {beerName: beer.name};
    });
    this.beerRestService.updateAvailableBeerTypes(this.venue.id, beersToSave).subscribe(availableBeers => {
      availableBeers.sort((a, b) => a.name.localeCompare(b.name));
      this.selectedBeersDisplay = availableBeers;
      this.initSelectedList = R.clone(availableBeers);
      this.cd.markForCheck();
    });
  }

  checkIdentical() {
    return R.equals(this.initSelectedList, this.selectedBeersDisplay);
  }

  private sortBeersList() {
    this.selectedBeersDisplay.sort((a, b) => a.name.localeCompare(b.name));
    this.beersDisplay.sort((a, b) => a.name.localeCompare(b.name));
  }
}
