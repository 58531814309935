import { BeerRestService } from './beerRest.service';
import { CalibrationRestService } from './calibrationRest.service';
import { OrderRestService } from './orderRest.service';
import { ReportRestService } from './reportRest.service';
import { SupportRestService } from './supportRest.service';
import { TapRestService } from './tapRest.service';
import { VenueRestService } from './venueRest.service';

export * from './calibrationRest.service';
export * from './tapRest.service';
export * from './beerRest.service';
export * from './venueRest.service';
export * from './orderRest.service';

export const APIS_CALIBRATION = [CalibrationRestService];

export const APIS_TAP = [TapRestService];

export const APIS_BEER = [BeerRestService];

export const APIS_VENUE = [VenueRestService];

export const APIS_REPORT = [ReportRestService];

export const APIS_ORDER = [OrderRestService];

export const APIS_SUPPORT = [SupportRestService];
