import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

const unitKey = 'unit';

@Injectable()
export class UnitService {
  currentUnit$: BehaviorSubject<string> = new BehaviorSubject('cel');

  constructor(private logger: NGXLogger) {
  }

  setUnit(unit: string): void {
    localStorage.setItem(unitKey, unit);
    this.currentUnit$.next(unit);
  }

  get unit(): BehaviorSubject<string> {
    const res = localStorage.getItem(unitKey);
    if (res) {
      this.currentUnit$.next(res);
    }
    return this.currentUnit$;
  }
}
