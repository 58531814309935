import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { NGXLogger } from 'ngx-logger';
import { asyncScheduler, Subscription } from 'rxjs';
import { flatMap, throttleTime } from 'rxjs/operators';
import { TapRestService, VenueRestService } from '../../core/rest';
import { ReportRestService } from '../../core/rest/api/reportRest.service';
import { DashboardHeadlinePO } from '../../core/rest/model/dashboardHeadlinePO';
import { DashboardPO } from '../../core/rest/model/dashboardPO';
import { DashboardTapPO } from '../../core/rest/model/dashboardTapPO';
import { CalibrationStatus, TapPO } from '../../core/rest/model/tapPO';
import { UsedBeerPO } from '../../core/rest/model/usedBeerPO';
import { VenuePO } from '../../core/rest/model/venuePO';
import { VenueService } from '../../core/services/venue.service';
import { TruncatePipe } from '../../shared/pipes/truncate.pipe';
import { PreloaderService } from '../../shared/services/preloader.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [TruncatePipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
  selectedVenue: VenuePO;
  taps: DashboardTapPO[];
  dashboardHeadline: DashboardHeadlinePO;
  subscription: Subscription;
  currentDate = new Date();
  chart = [];
  @ViewChild('canvas', {static: false}) canvas: ElementRef;
  usedBeerCount;
  totalSales: number;
  glassResource: string[];
  usedBeer: UsedBeerPO[] = [];

  constructor(private router: Router,
              private tapRestService: TapRestService,
              private logger: NGXLogger,
              private venueService: VenueService,
              private route: ActivatedRoute,
              private truncatePipe: TruncatePipe,
              private angularFirestore: AngularFirestore,
              private snackBar: MatSnackBar,
              private preloader: PreloaderService,
              private translate: TranslateService,
              private venueRestService: VenueRestService,
              private reportRestService: ReportRestService,
              private cd: ChangeDetectorRef) {
    this.logger.trace('Dashboard Page constructor');
    this.preloader.show(true);
  }

  async ngOnInit() {
    this.route.queryParamMap.subscribe(async () => {
      this.ngOnDestroy();
      this.resetChartAfterVenueChanged();
      this.selectedVenue = await this.venueService.getSelectedVenue();
      if (this.selectedVenue) {
        this.updateDashboardAfterPour(this.selectedVenue.id);
      } else {
        this.preloader.hide();
      }
    });
  }

  getTapStatus(tap: DashboardTapPO) {
    return tap.maintenance || tap.kegDetached || tap.calibrationStatus !== CalibrationStatus.CALIBRATED;
  }

  lockTapDetails(tap: DashboardTapPO): void {
    if (this.getTapStatus(tap)) {
      this.snackBar.open(this.translate.instant('tap-details-lock-message'), this.translate.instant('close'), {
        duration: 8000
      });
    }
  }

  private updateDashboardAfterPour(selectedVenueId: string) {
    this.subscription = this.getFirestoreNotificationCollection(selectedVenueId).valueChanges().pipe(
      throttleTime(10000, asyncScheduler, {
        leading: true,
        trailing: true
      }),
      flatMap(() => this.tapRestService.getDashboardElements(selectedVenueId))
    ).subscribe((dashboard: DashboardPO) => {
      this.prepareChart();
      this.taps = dashboard.dashboardElements.map(value => {
        return {...value, flip: false, beerInfo: {...value.beerInfo, glass: this.setGlassResource(value)}};
      });
      this.dashboardHeadline = dashboard.headline;
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.snackBar.dismiss();
  }

  private setGlassResource(tap: TapPO) {
    if (tap && tap.beerInfo && tap.beerInfo.glass && typeof tap.beerInfo.glass === 'string') {
      return tap.beerInfo.glass.split(',').map((resource: string) => {
        if (resource.charAt(0) === ' ') {
          return resource.substring(1);
        } else {
          return resource;
        }
      });
    }
  }

  flip($event: MouseEvent, tapId: number) {
    $event.preventDefault();
    $event.stopPropagation();
    const tapElement = this.taps.find((tapItem) => tapItem.tapId === tapId);
    tapElement.flip = !tapElement.flip;
  }

  prepareChart() {
    this.reportRestService.getAvailableBeerTypes(this.selectedVenue.id).subscribe((usedBeer: UsedBeerPO[]) => {
      this.usedBeer = usedBeer;
      if (usedBeer.length !== 0) {
        this.usedBeerCount = usedBeer.length;
        usedBeer = usedBeer.sort((a, b) => b.usedBeer - a.usedBeer);
        const canvas = (document.getElementById('canvas') as any);
        if (this.chart.length !== 0) {
          // @ts-ignore
          this.chart.destroy();
        }
        this.chart = new Chart(canvas.getContext('2d'), {
          type: 'horizontalBar',
          data: {
            datasets: [
              {
                data: usedBeer.map(value => value.usedBeer / 1000),
                backgroundColor: this.setBarColor(usedBeer)

              }],
            labels: usedBeer.map(value => this.truncatePipe.transformWithoutMark(value.beerName, 20))
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                top: 5

              }
            },
            legend: {
              display: false
            },
            tooltips: {
              displayColors: false,
              backgroundColor: 'rgb(0, 0, 0)'
            },
            scales: {
              xAxes: [{
                ticks: {
                  min: 0, // Edit the value according to what you need
                  fontColor: '#666A6F'

                },
                gridLines: {
                  color: '#666A6F'
                },
                scaleLabel: {
                  display: true,
                  labelString: this.translate.instant('liters'),
                  fontSize: 16,
                  fontColor: '#666A6F'
                }
              }],
              yAxes: [{
                barThickness: 15,
                categoryPercentage: 2.0,
                barPercentage: 2.0,
                gridLines: {
                  display: false,
                  color: '#666A6F'
                },
                ticks: {
                  display: false,
                  fontColor: '#666A6F'
                }
              }]
            },
            animation: {
              duration: 1,
              onProgress() {
                const ctx = this.chart.ctx;
                // tslint:disable-next-line:max-line-length
                ctx.font = Chart.helpers.fontString('normal');
                ctx.textAlign = 'left';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(dataset => {
                  for (let i = 0; i < dataset.data.length; i++) {
                    // tslint:disable-next-line:one-variable-per-declaration
                    const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                      left = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._xScale.left;
                    ctx.fillStyle = '#9B9B9B'; // label color
                    ctx.font = 'normal 12px Open Sans, sans-serif';

                    const label = model.label;
                    ctx.fillText(label, left, model.y - 12);
                  }
                });
              }
            }
          }
        });
        // @ts-ignore
        this.chart.update();
        this.setTotalSales(usedBeer);
      }
    });

  }

  setTotalSales(usedBeer: UsedBeerPO[]) {
    this.totalSales = 0;
    usedBeer.map(value => {
      this.totalSales += value.usedBeer / 1000;
    });
  }

  private setBarColor(usedBeer: UsedBeerPO[]) {
    if (usedBeer.length >= 0) {
      const colors = usedBeer.map(value => '#666A6F');
      const usedBeers = usedBeer.map(value => value.usedBeer);
      const maxValue = Math.max(...usedBeers);
      const maxValueIndexes = [];
      usedBeers.forEach((value, index) => value === maxValue ? maxValueIndexes.push(index) : null);
      maxValueIndexes.forEach(value => {
        colors[value] = 'rgb(222,165,33)';
      });
      return colors;
    }
  }

  private resetChartAfterVenueChanged() {
    if (this.chart.length !== 0) {
      this.totalSales = null;
      // @ts-ignore
      this.chart.destroy();
    }
  }

  formatLabel(value: number, type: string) {
    if (type === 'temp') {
      return `${value + 'C'}`;
    } else if (type === 'alco') {
      return `${value + '%'}`;
    } else {
      return `${value}`;
    }
  }

  getCalibrationStatus(tap: DashboardTapPO) {
    return tap.calibrationStatus === CalibrationStatus.CALIBRATED || tap.kegDetached ? '' : this.translate.instant('not-calibrated');
  }

  private getFirestoreNotificationCollection(venueId: string): AngularFirestoreDocument<any> {
    return this.angularFirestore.collection('events').doc('pour_on_venue').collection(venueId).doc('v');
  }
}
