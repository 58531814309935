import { Component, OnInit } from '@angular/core';
import { LoaderState, PreloaderService } from '../../services/preloader.service';


@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
  show = false;
  initApp = false;

  constructor(private loaderService: PreloaderService) {
  }

  ngOnInit() {
    this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.addClassWhenPreloaderActive(state);
        this.initApp = state.initApp;
        this.show = state.show;
      });
  }

  private addClassWhenPreloaderActive = (state: LoaderState) => {
    if (state && state.show) {
      document.querySelector('body').classList.add('preloader-active');
    } else {
      document.querySelector('body').classList.remove('preloader-active');
    }
  }
}
