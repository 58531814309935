import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PreloaderService } from '../shared/services/preloader.service';
import { AuthGuard } from './guards/auth.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { APIS_BEER, APIS_CALIBRATION, APIS_ORDER, APIS_REPORT, APIS_SUPPORT, APIS_TAP, APIS_VENUE } from './rest';
import { AuthService } from './security/services/auth.service';
import { LanguageService } from './services/language.service';
import { UnitService } from './services/unit.service';
import { VenueService } from './services/venue.service';

@NgModule({
    imports: [HttpClientModule]
  }
)
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        PendingChangesGuard,
        VenueService,
        LanguageService,
        TranslateService,
        UnitService,
        AuthService,
        PreloaderService,
        AuthGuard,
        ...APIS_CALIBRATION,
        ...APIS_TAP,
        ...APIS_BEER,
        ...APIS_VENUE,
        ...APIS_REPORT,
        ...APIS_ORDER,
        ...APIS_SUPPORT
      ]
    };
  }
}
