import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MenuModel } from '../../core/pages/model/menuModelPO';
import { VenuePO } from '../../core/rest/model/venuePO';
import { AuthService } from '../../core/security/services/auth.service';
import { VenueService } from '../../core/services/venue.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [   // :enter is alias to 'void => *'
          style({opacity: 0}),
          animate(200, style({opacity: 1}))
        ]),
        transition(':leave', [   // :leave is alias to '* => void'
          animate(200, style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class MenuItemComponent implements OnInit {
  @Input() link: MenuModel;
  @Input() pageRolesRestriction: string[];
  @Input() menuState: boolean;
  @Input() selectedVenue: VenuePO;

  constructor(private venueService: VenueService,
              private authService: AuthService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  showMenuItemBasedOnRole() {
    if (this.pageRolesRestriction) {
      return this.authService.hasPermission(this.pageRolesRestriction);
    } else {
      return true;
    }
  }

  getQueryParam() {
    if (this.selectedVenue) {
      return {venue: this.selectedVenue.id};
    }
  }
}
