import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OrderRestService } from '../../../core/rest/api/orderRest.service';
import { OrderUserPO } from '../../../core/rest/model/orderUserPO';
import { PreloaderService } from '../../../shared/services/preloader.service';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'app-new-taps-modal',
  templateUrl: 'new-taps-modal.html',
  styleUrls: ['./new-taps-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTapsModalComponent implements OnInit {
  formGroup: FormGroup;
  emailOptions: OrderUserPO[];
  filteredEmailOptions: Observable<OrderUserPO[]>;
  venueOptions = [];
  filteredVenueOptions: Observable<string[]>;

  constructor(private orderRestService: OrderRestService,
              private preloader: PreloaderService,
              private dialogRef: MatDialogRef<NewTapsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {
    this.preloader.show();
    this.prepareFormGroup();
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    this.orderRestService.retrieveUsers().subscribe((users) => {
      this.emailOptions = users;
      this.filteredEmailOptions = this.formGroup.get('email').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterEmails(value))
        );
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }

  closeModal(result: boolean) {
    console.log(this.formGroup.getRawValue());
    if (result) {
      this.dialogRef.close(this.formGroup.getRawValue());
    } else {
      this.dialogRef.close(result);
    }
  }

  prepareFormGroup(): void {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(Utils.emailValidator)]),
      venueName: new FormControl('', Validators.required),
      isOtherAddress: new FormControl(),
      otherAddress: new FormControl(''),
      tapsNumber: new FormControl(null, [Validators.required, Validators.min(1)]),
      invoiceDetails: new FormControl('', Validators.required)
    });
  }

  changeAddressValidator(checkbox: MatCheckboxChange) {
    const otherAddress = this.formGroup.get('otherAddress');
    if (checkbox.checked) {
      otherAddress.setValidators(Validators.required);
    } else {
      otherAddress.clearValidators();
      otherAddress.reset('');
    }
  }

  private _filterEmails(value): OrderUserPO[] {
    const filterValue = value.email !== undefined ? value.email.toLowerCase() : value.toLowerCase();
    if (this.emailOptions) {
      return this.emailOptions.filter(option => option.email.toLowerCase().includes(filterValue));
    }
  }

  private _filterVenues(value): any {

    const filterValue = value.toLowerCase();
    return this.venueOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  get getIsOtherAddressValue() {
    return this.formGroup.get('isOtherAddress').value;
  }

  prepareVenuesForUser(option: OrderUserPO) {
    this.preloader.show();
    this.orderRestService.retrieveUserVenues(option.email).subscribe(venues => {
      this.venueOptions = venues;
      this.filteredVenueOptions = this.formGroup.get('venueName').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterVenues(value))
        );
      this.formGroup.get('venueName').reset('');
      this.preloader.hide();
      this.cd.markForCheck();
    });
  }
}
