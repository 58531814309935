import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BeerRestService } from '../../core/rest';
import { Step } from '../../core/utils/step';

const BARREL_SIZE = 31;

@Component({
  selector: 'app-keg-size-modal',
  templateUrl: 'keg-size-modal.html',
  styleUrls: ['./keg-size-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KegSizeModalComponent implements OnInit {

  step: Step;
  beerName: string;
  formGroup: FormGroup;
  selectedSize: number;

  constructor(private dialogRef: MatDialogRef<KegSizeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private beerRestService: BeerRestService,
              private cd: ChangeDetectorRef) {
    this.prepareFormGroup();
  }

  ngOnInit(): void {
    if (this.data) {
      this.step = this.data.step;
      this.beerName = this.data.beerName;
    }

    this.cd.markForCheck();
  }

  selectBeerSize(kegSize: number | string) {
    if (typeof kegSize === 'string') {
      kegSize = BARREL_SIZE * (+kegSize);
    }
    this.formGroup.setValue({kegSize});
    this.selectedSize = kegSize;
    this.cd.markForCheck();
  }

  dismiss(close) {
    if (close) {
      this.dialogRef.close(close);
    } else {
      this.dialogRef.close(null);
    }
  }

  prepareFormGroup() {
    this.formGroup = new FormGroup({
      kegSize: new FormControl(null, Validators.required)
    });
  }


  get getKegSize(): string {
    const value = this.formGroup.getRawValue();
    return value.kegSize ? `(${value.kegSize}` : '';
  }

  stepBack() {
    this.dialogRef.close({back: true});
  }

  goNextStep() {
    const value = this.formGroup.getRawValue();
    if (value) {
      this.dismiss(value.kegSize);
    }
  }
}

