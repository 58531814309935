import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TapPO } from '../../core/rest/model/tapPO';

@Component({
  selector: 'app-keg-summary-modal',
  templateUrl: 'keg-summary-modal.html',
  styleUrls: ['./keg-summary-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KegSummaryModalComponent {

  previousTap: TapPO;

  constructor(private dialogRef: MatDialogRef<KegSummaryModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef) {

    if (this.data) {
      this.previousTap = this.data.previousTap;
      this.cd.markForCheck();
    }
  }

  dismiss(newData: boolean) {
    this.dialogRef.close(newData);
  }
}
